import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { ExportItemContext } from '../../context/ExportItemContext';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(6),
    background: 'rgba(0, 0, 0, 0.04)'
  }
}))

const ExportItemToUnit = ({ unit, parentAccountId, parentCheckboxHandler }) => {
  const classes = useStyles();
  const { importAccountList, setImportAccountList } = useContext(ExportItemContext);
  const [state, setState] = useState(false);

  useEffect(() => {
    if (!importAccountList[parentAccountId]) return setState(false);
    for (const accountUnit of importAccountList[parentAccountId].unit) {
      if (accountUnit.id === unit.id) {
        setState(true);
        break;
      }
    }
  }, [importAccountList])

  const checkboxHandler = e => {
    if (importAccountList[parentAccountId]) {
      if (!importAccountList[parentAccountId].unit.length) {
        setImportAccountList(prevState => {
          prevState[parentAccountId].unit.push(unit);
          return { ...prevState }
        })
        return setState(true);
      }

      for (const accountUnit of importAccountList[parentAccountId].unit) {
        if (accountUnit.id === unit.id) {
          // If unit already exists in the acount, remove unit group
          setImportAccountList(prevState => {
            const updatedUnitList = prevState[parentAccountId].unit.filter(item => {
              return item.id !== unit.id;
            });
            // If updatedUnitList is empty, remove account form import list
            prevState[parentAccountId].unit = updatedUnitList;
            return { ...prevState }
          })
          return setState(false);
        }
      }
      // If unit doesn't exist in the import list, add unit group
      setImportAccountList(prevState => {
        prevState[parentAccountId].unit.push(unit);
        return { ...prevState }
      })
      setState(true);
    } else {
      parentCheckboxHandler(e, unit.id)
    }

  }

  return (
    <ListItem key={unit.id} dense className={classes.nested}>
      <ListItemIcon>
        <Checkbox color="primary" checked={state} onClick={checkboxHandler} />
      </ListItemIcon>
      <ListItemText>{unit.nm}</ListItemText>
    </ListItem>
  )
}

export default ExportItemToUnit;