import React from 'react';
import { Backdrop, Button, Fade, Grid, makeStyles, Modal, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(1, 2, 1),
    minHeight: 120,
    width: 320,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    margin: "0 10px",
    width: 85
  }
}));

const CustomModal = ({ status, setStatus, onSubmit, title, renderMessage, style }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={status}
      onClose={() => setStatus(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={status}>
        <Paper className={classes.paper} style={style}>
          <Grid item style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid item style={{margin: '10px 0'}}>
              <Typography variant="body1" align="left">{title}</Typography>
              {renderMessage ? renderMessage() : null}
            </Grid>
            <Grid item style={{margin: '10px 0'}} >
              <Button
                id="modalConfirmBtn"
                className={classes.button}
                color="primary"
                size="small"
                variant="contained"
                onClick={() => {
                  onSubmit();
                  setStatus(false);
                }}
              >
                Confirm
                </Button>
              <Button
                className={classes.button}
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => setStatus(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
}

export default CustomModal;