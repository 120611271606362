import React, { useContext } from 'react';
import { makeStyles, Drawer, List, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { A } from 'hookrouter';

import addPerson from '../img/add-person.svg';
import addDevices from '../img/add-device.svg';
import logout from '../img/logout.svg';
import exportLogo from '../img/export-logo.svg';
import importLogo from '../img/import-logo.svg';
import reportLogo from '../img/report-logo.svg';
import { AppContext } from '../AppContext';
import { apiPost } from '../lib/network';

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  link: {
    color: 'inherit',
    textDecoration: 'none'
  },
  toolbar: theme.mixins.toolbar,
  svg: {
    filter: 'invert(0.5) sepia(1) saturate(20) hue-rotate(190deg)',
    width: 30
  }
}));

const MenuDrawer = ({ state, setState }) => {
  const classes = useStyles();
  const { appState, setAppState, setLoadingDOM } = useContext(AppContext);

  const logoutHandler = async () => {
    // setLoadingDOM(true);
    try {
      const response = await apiPost(process.env.REACT_APP_API_SERVER, {
        cmd: "wialon.logout",
        params: {}
      });
      if (response) {
      }
    } catch (err) {
      console.error(err);
    }
    
    await setAppState({ ...appState, auth: { loggedIn: false, user: '', session: '' } });
    // setLoadingDOM(false);
  }

  return (
    <Drawer anchor='left' open={state} onClose={() => setState(false)}>
      <div role="presentation" onClick={() => setState(!state)}>
        <div className={classes.toolbar} />
        <Divider />
        <List className={classes.list}>
          <A href="new-customer" className={classes.link}>
            <ListItem button>
              <ListItemIcon>
                <img className={classes.svg} src={addPerson} alt='add new customer page' />
              </ListItemIcon>
              <ListItemText primary={"New Customer"} />
            </ListItem>

          </A>
          <A href="/add-devices" className={classes.link}>
            <ListItem button>
              <ListItemIcon>
                <img className={classes.svg} src={addDevices} alt='add new device page' />
              </ListItemIcon>
              <ListItemText primary={"Add Devices"} />
            </ListItem>
          </A>
          <A href="/export-wlp" className={classes.link}>
            <ListItem button>
              <ListItemIcon >
                <img className={classes.svg} style={{ width: 18, paddingLeft: 5 }} src={exportLogo} alt='export wlp' />
              </ListItemIcon>
              <ListItemText primary={"Export WLP"} />
            </ListItem>
          </A>
          <A href="/import-wlp" className={classes.link}>
            <ListItem button>
              <ListItemIcon >
                <img className={classes.svg} style={{ width: 26, paddingLeft: 1 }} src={importLogo} alt='import wlp' />
              </ListItemIcon>
              <ListItemText primary={"Import WLP"} />
            </ListItem>
          </A>
          {appState.auth && appState.auth.user === 'miplatform'
            ? <A href="/reports" className={classes.link}>
              <ListItem button>
                <ListItemIcon >
                  <img className={classes.svg} style={{ width: 25, paddingLeft: 1}} src={reportLogo} alt='report' />
                </ListItemIcon>
                <ListItemText primary={"Reports"} />
              </ListItem>
            </A>
            : null
          }
          <A href="/" className={classes.link} onClick={logoutHandler}>
            <ListItem button>
              <ListItemIcon >
                <img className={classes.svg} style={{ width: 25, paddingLeft: 5 }} src={logout} alt='logout' />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
          </A>
        </List>
      </div>
    </Drawer>
  );
}

export default MenuDrawer;