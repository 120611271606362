import React, { useState } from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import AccountDetail from './AccountDetail';
import AccountSetting from './AccountSetting';
import OptionalSetting from './OptionalSetting';
import CustomModal from '../common/CustomModal';
import { apiPost } from '../../lib/network';
import { navigate } from 'hookrouter';
import { convertTimezoneValue } from '../common/common';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 2),
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    marginTop: 35
  }
}))

const NewCustomer = () => {
  const classes = useStyles();
  const [submitStatus, setSubmitStatus] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [inputError, setInputError] = useState({
    companyName: false,
    accountEmail: false,
    accountUser: false,
    creatorId: false,
    billingPlan: false,
    timezone: false
  });
  const [isUnique, setIsUnique] = useState({ accountName: true, accountUsername: true })

  const { enqueueSnackbar } = useSnackbar();
  const snack = (msg, variant) => enqueueSnackbar(msg, { variant });

  const [accountDetail, setAccountDetail] = useState({ company: '', email: '', username: '', accountCreator: '', billingPlan: '' });
  const [accountSetting, setAccountSetting] = useState({ measurement: 1, timezone: '', dst: '0x08000000' });
  const [additionalSetting, setAdditionalSetting] = useState({ welcomeEmail: [], subAccount: '' });

  const createNewCustomer = async () => {
    setSubmitStatus(true);

    const companyName = accountDetail.company.trim(),
      creatorId = accountDetail.accountCreator,
      accountEmail = accountDetail.email.trim(),
      accountUsername = accountDetail.username.trim(),
      billingPlan = accountDetail.billingPlan,
      geofence = additionalSetting.subAccount,
      measurementSystem = accountSetting.measurement,
      timezoneValue = convertTimezoneValue(accountSetting.timezone, accountSetting.dst),
      dst = accountSetting.dst,
      welcomeEmail = `${accountEmail},${additionalSetting.welcomeEmail.join(',').trim()}`

    const params = { companyName, welcomeEmail, creatorId, accountEmail, username: accountUsername, billingPlan, geofence, measurementSystem, timezoneValue, dst }

    if (companyName && ( companyName.length > 4 && companyName.length < 50 ) && creatorId && accountEmail && accountUsername && billingPlan && timezoneValue) {
      try {
        const response = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: "wialon.createAccount", params });

        if (response) {
          if (response.success === true) {
            snack('Successfully created a new account. Redirecting to Add Device.', 'success');
            setTimeout(() => navigate('/add-devices'), 4000)
          } else {
            let result;
            try {
              const parsedResult = JSON.parse(response.result);
              result = parsedResult.reason || parsedResult.error;
              if (result === "NAME_ALREADY_EXISTS") {
                result = "Username already exists"
              }
            } catch (err) {
              result = response.result;
            }

            if (result === "Username already exists, Company name already exists") {
              setInputError({ ...inputError, companyName: true, accountUser: true })
            } else if (result === "Username already exists") {
              setInputError({ ...inputError, accountUser: true })
            } else if (result === "Company name already exists") {
              setInputError({ ...inputError, companyName: true })
            }
            snack(`Error creating customer:\n ${result}`, 'error')
          }

        } else {
          snack('Error creating customer:\nNo response - request timed out.', 'error')
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      // Depending on whether company name and account username is unique
      let companyNameErrState = false;
      let accountUsernameErrState = false;
      if ( !companyName || !isUnique.accountName ) {
        companyNameErrState = true
      }
      if (!accountUsername || !isUnique.accountUsername) {
        accountUsernameErrState = true;
      }
      if ( companyName.length < 4 && companyName.length > 50 ) {
        companyNameErrState = true
        snack('Error: Account name too long. Must be between 4 and 50 characters.', 'error');
      }
      
      setInputError({ companyName: companyNameErrState, accountEmail: !accountEmail, accountUser: accountUsernameErrState, creatorId: !creatorId, billingPlan: !billingPlan, timezone: !timezoneValue })
      snack('Error: Missing input. Double check the form inputs.', 'error')
    }

    setSubmitStatus(false)
  }

  return (
    <Grid container className={classes.container}>
      <Grid item sm={8} md={6}>
        <Typography variant="h4" align="center">Create New Customer</Typography>
        <Grid container>
          <AccountDetail
            detail={accountDetail}
            setDetail={setAccountDetail}
            error={inputError}
            setError={setInputError}
            isUnique={isUnique}
            setIsUnique={setIsUnique}
          />
          <AccountSetting
            setting={accountSetting}
            setSetting={setAccountSetting}
            error={inputError}
            setError={setInputError}
          />
          <OptionalSetting
            setting={additionalSetting}
            setSetting={setAdditionalSetting}
            dealerId={accountDetail.accountCreator}
            error={inputError}
            setError={setInputError}
          />

          <Button
            id="submitBtn"
            className={classes.button}
            color="primary"
            variant="contained"
            fullWidth
            disabled={submitStatus}
            onClick={() => { setModalStatus(true) }}
          >
            Submit
          </Button>

          <CustomModal
            status={modalStatus}
            setStatus={setModalStatus}
            onSubmit={createNewCustomer}
            title={'Please Confirm To Create A New Customer.'}
            renderMessage={() => (
              < Grid item style={{ margin: '10px 0 10px' }} >
                <Typography variant="subtitle2" color="error">**Note: For new accounts, please create WLP and import into the account.**</Typography>
              </Grid>
            )}
          />

        </Grid>
      </Grid>
    </Grid>
  )
}

export default NewCustomer;