import React, { useState } from 'react';
import { Checkbox, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(6),
  }
}))

const ImportOptionSubCategory = ({ value, total, options, setOptions }) => {
  const classes = useStyles();
  const [state, setState] = useState(false);

  const handleChange = () => {
    const index = options.list.indexOf(value);
    if (options.all && index !== -1) {
      setOptions(prevState => {
        const filteredList = prevState.list.filter(item => item !== value);
        return { list: filteredList, all: false }
      })
      setState(false);
    } else if (!options.all && index !== -1) {
      setOptions(prevState => {
        const filteredList = prevState.list.filter(item => item !== value);
        return { list: filteredList, all: prevState.all }
      });
      setState(false)
    } else if (!options.all && index === -1) {
      setOptions(prevState => {
        let mainStatus = false;
        if (total === options.list.length + 1) {
          mainStatus = true;
        }
        return { list: [...options.list, value], all: mainStatus }
      })
      setState(true);
    }
  }

  const checkboxState = () => {
    if (options.all) return true;
    if (!options.list.length) return false;
    if ( options.list.indexOf( value ) !== -1 ) return true;
    return state
  }

  return (
    <ListItem dense className={classes.nested}>
      <ListItemIcon>
        <Checkbox color="primary" checked={checkboxState()} onChange={e => handleChange(e, value)} />
      </ListItemIcon>
      <ListItemText>{value.n}</ListItemText>
    </ListItem>
  )
}

export default ImportOptionSubCategory;