import React, { useState, useContext } from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import SalesOrder from './SalesOrder';
import DeviceList from './DeviceList';
import Platform from './Platform';
import CustomModal from '../common/CustomModal';
import { apiPost } from '../../lib/network';
import { AppContext } from '../../AppContext';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 2),
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    marginTop: 35
  }
}))

const AddDevice = () => {
  const classes = useStyles();
  const { appState } = useContext(AppContext);
  const [modalStatus, setModalStatus] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [inputError, setInputError] = useState({
    deviceList: false,
    creatorId: false,
    deviceType: false,
    hwTypeId: false,
  })

  const { enqueueSnackbar } = useSnackbar();
  const snack = (msg, variant) => enqueueSnackbar(msg, { variant });

  const [orderNum, setOrderNum] = useState('');
  const [scanlistById, setScanlistById] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [platform, setPlatform] = useState('mifleet');

  const [deviceAccount, setDeviceAccount] = useState('');
  const [unitGroup, setUnitGroup] = useState('');
  const [hardwareType, setHardwareType] = useState({ id: '', name: '' });
  const [usc, setUsc] = useState(false);

  const createNewUnits = async () => {
    setSubmitStatus(true);
    if (platform === 'mifleet') {
      if (deviceList.length && deviceAccount && hardwareType.name && hardwareType.id) {
        //Removes empty strings added in manual array than trims each item for any whitespace
        const devices = deviceList.map(device => device.trim()).filter(device => device.length);

        const params = {
          creatorId: deviceAccount,
          deviceType: hardwareType.name,
          hwTypeId: hardwareType.id,
          unitgroupId: unitGroup,
          usc
        }

        try {
          const response = await apiPost(process.env.REACT_APP_API_SERVER, {
            cmd: "wialon.createUnits",
            params,
            data: devices
          });

          if (response) {
            if (response.success === true) {
              snack('Successfully created a new unit.', 'success');
              setTimeout(() => window.location.reload(), 2000);
            } else if (!response.success && response.result) {
              setInputError({ ...inputError, deviceList: true })
              if (response.result.msg === "Device ID already exists for this model.") {
                snack(`Following device ID alreadys exists for this model: ${response.result.data.join(', ')}`, 'error')
              }
            }
          } else {
            snack('Error creating customer:\nNo response - request timed out.', 'error')
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        setInputError({
          deviceList: !deviceList.length,
          creatorId: !deviceAccount,
          deviceType: !hardwareType.name,
          hwTypeId: !hardwareType.id,
        })
        snack('Error: Missing input. Double check the form inputs.', 'error')
      }
    }
    setSubmitStatus(false);
  }

  return (
    <Grid container className={classes.container} >
      <Grid item sm={8} md={6}>
        <Typography variant="h4" align="center">Add Devices</Typography>
        <Grid container>
          {appState.auth.user === 'miplatform'
            ? <SalesOrder orderNum={orderNum} setOrderNum={setOrderNum} scanlist={scanlistById} setScanlist={setScanlistById} />
            : null
          }
          <DeviceList orderNum={orderNum} scanlist={scanlistById} deviceList={deviceList} setDeviceList={setDeviceList} error={inputError} setError={setInputError} appState={appState} />
          <Platform
            deviceAccount={deviceAccount}
            setDeviceAccount={setDeviceAccount}
            setUnitGroup={setUnitGroup}
            hardwareType={hardwareType}
            setHardwareType={setHardwareType}
            platform={platform}
            setPlatform={setPlatform}
            usc={usc}
            setUsc={setUsc}
            error={inputError}
            setError={setInputError}
          />
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            fullWidth
            disabled={submitStatus}
            onClick={() => { setModalStatus(true) }}
          >
            Submit
          </Button>
          <CustomModal
            status={modalStatus}
            setStatus={setModalStatus}
            onSubmit={createNewUnits}
            title={"Please Confirm To Add New Devices."}
            renderMessage={() => (
              < Grid item style={{ margin: '10px 0 10px' }} >
                <Typography variant="subtitle2" color="error">**Note: For new devices, please use existing CMS template and import into all new units.**</Typography>
              </Grid>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddDevice;