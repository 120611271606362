import React, { useState, useEffect } from 'react';
import { Grid, List, ListSubheader, makeStyles, Paper, TextField, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { apiPost } from '../../../lib/network';
import CustomSkeleton from '../../common/CustomSkeleton';
import ImportDestinationAccount from './ImportDestinationAccount';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  paper: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: 400
  },
  subheader: {
    padding: '10px 20px 10px',
    borderBottom: '1px solid lightgray',
    background: 'rgb(241, 241, 241)',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    width: '100%'
  }
}))

const ImportDestination = ({ accounts, setAccounts }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [accountList, setAccountList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentListByPageNum, setCurrentListByPageNum] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [cachedPage, setCachedPage] = useState([]);
  const [filteredCachedPage, setFilteredCachedPage] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    const getDealers = async () => {
      const response = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: "wialon.getAccountsAll" });
      if (response && response.success && !isCancelled) {
        const list = response.result.items;
        setAccountList(list);
        setCurrentListByPageNum(list.slice(0, 20));
      } else {
        console.error(response);
        //TODO: ADD ERROR SNACK
      }

    }
    getDealers();

    return () => { isCancelled = true }
  }, []);

  useEffect(() => {
    if (!currentListByPageNum.length && !searchValue) return;
    let isCancelled = false;

    const getUnitGroup = async () => {
      if ((!searchValue && cachedPage.indexOf(pageNum) === -1) || (searchValue && filteredCachedPage.indexOf(pageNum) === -1)) {
        const accountIdByPage = currentListByPageNum.map( item => item.crt );
        const response = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: "wialon.getUnitgroupsInAccountByBatch", params: { accounts: accountIdByPage } });
        if (response && response.success) {
          for (let account of currentListByPageNum) {
            for (let batchItem of response.result) {
              if (batchItem.searchSpec.propValueMask == account.crt) {
                account.unit = batchItem.items;
                break;
              }
            }
          }
        }
        if (!searchValue) {
          setCachedPage([...cachedPage, pageNum]);
        } else {
          setFilteredCachedPage([...filteredCachedPage, pageNum]);

        }
      }
      if (!isCancelled) setIsLoading(false);
    }
    getUnitGroup();

    return () => { isCancelled = true }
  }, [currentListByPageNum])

  const handleAccountCheckboxOnClick = (e, data) => {
    e.stopPropagation();
    if (accounts.length) {
      if (e.target.checked) {
        setAccounts([...accounts, data]);
      } else {
        const updatedList = accounts.filter(item => item.id !== data.id);
        setAccounts(updatedList);
      }
    } else {
      setAccounts([data]);
    }
  }

  const handlePagination = (e, page) => {
    let start;
    if (page === 1) {
      start = 0;
    } else {
      start = (page - 1) * 20 + 1;
    }
    let end = start + 20 // increments of 20;


    setIsLoading(true);
    setPageNum(page);

    if (searchValue) {
      setCurrentListByPageNum(filteredList.slice(start, end));
    } else {
      setCurrentListByPageNum(accountList.slice(start, end));
    }
  }

  const filterList = e => {
    const searchValue = e.target.value;
    setSearchValue(searchValue);
    setPageNum(1);
    setFilteredCachedPage([]);

    const copiedList = JSON.parse(JSON.stringify(accountList));
    const regex = new RegExp(searchValue, 'i');
    const filteredList = copiedList.filter(account => regex.test(account.nm));
    setFilteredList(filteredList);
    setCurrentListByPageNum(filteredList.slice(0, 20));
  }

  const renderList = () => {
    return currentListByPageNum.map(item => (
      <ImportDestinationAccount
        key={item.id}
        value={item}
        accounts={accounts}
        setAccounts={setAccounts}
        handleAccountCheckbox={handleAccountCheckboxOnClick}
      />
    ))
  }

  return (
    <Paper className={classes.paper}>
      <ListSubheader variant="subtitle1" className={classes.subheader}>
        Destination Items
        <Tooltip
          disableFocusListener
          disableTouchListener
          placement="right"
          style={{ paddingLeft: 10 }}
          title={`Destination items will pull any accounts with an existing unit group in the account. Notification template will only be added to accounts with a unit group or if the unit group is checked.`}
        >
          <HelpOutlineIcon fontSize="small" />
        </Tooltip>
      </ListSubheader>
      <TextField
        placeholder="Search"
        disabled={isLoading}
        className={classes.input}
        inputProps={{ style: { padding: "15px 20px" } }}
        value={searchValue}
        onChange={filterList}
      />
      {!isLoading
        ? <List>
          {renderList()}
        </List>
        : <CustomSkeleton style={{ border: 'none', margin: 10, height: 40 }} number={5} />
      }
      <Grid style={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px' }}>
        {accountList.length && !isLoading
          ? <Pagination
            count={!searchValue ? Math.ceil(accountList.length / 20) : Math.ceil(filteredList.length / 20)}
            page={pageNum}
            onChange={handlePagination}
            size="small"
          />
          : null
        }
      </Grid>
    </Paper>
  )

}

export default ImportDestination;