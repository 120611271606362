const nonBillableAccounts = [
  15353183, // 0 - DCS Testing and Templates
  15353188, // 0 - DCS Testing and Templates Metric
  16135424, // DCS - MF Transportation Services
  13948496, // dcs 000 MiFleet1
  12892248, // DCS Employees
  13708790, // rrichey
  13949560, // OSullivan
  14126246, // Demo-usc-rlavender
  14466720, // DCS TEST Dealer (acct)
  null,     // miplatform
  400009156,// $$$test
  400006302 // miplatform
]

export const getFirstOfMonth = (dateInput) => {
  const IntlOpts = {
    month: "long",
    year: "numeric"
  };
  const date = new Date(dateInput.getFullYear(), dateInput.getMonth(), 1);
  const label = new Intl.DateTimeFormat("en-US", IntlOpts).format(date);

  return {
    date: date,
    label: label
  }
}

export const getPreviousMonth = (dateInput) => {
  const IntlOpts = {
    month: "long",
    year: "numeric"
  };
  const date = new Date(dateInput.getFullYear(), dateInput.getMonth() - 1, 1);
  const label = new Intl.DateTimeFormat("en-US", IntlOpts).format(date);

  return {
    date: date,
    label: label
  }
}

export const parseUnitsData = (datasetA, datasetB, datasetC, datasetD) => {
  if (!datasetA) throw new Error("No dataset specified.");
  if (!datasetB || datasetB.length < 1) alert("Data not found. Data begins in December 2018.");

  let result;

  const parseDataset = (unitsInput) => {
    if (!unitsInput) throw new Error("No data found for units.");

    const customers = {};
    const customersSorted = [];
    let unitsTotalDeactivated = 0;

    // Group units by customer
    for (let i = 0, l = unitsInput.length; i < l; i++) {
      const thisUnit = unitsInput[i];
      const accountId = thisUnit.ourcustomer_account_id;
      if (!customers[accountId]) {
        customers[accountId] = {
          accountId: accountId,
          accountName: thisUnit.ourcustomer_account_name,
          unitsTotal: 0,
          units: {},
          unitsTotalDeactivated: 0,
        };
      }
      customers[accountId].unitsTotal++;
      customers[accountId].units[thisUnit.unit_id] = thisUnit; // Key is wialon unit ID, not ESN. Must do this because not all units have an ESN assigned.
      if (thisUnit.active === 0) {
        customers[accountId].unitsTotalDeactivated++;
        unitsTotalDeactivated++;
      }
    }

    // Sort the customers by unit total
    for (let each in customers) {
      if (customers.hasOwnProperty(each)) {
        customersSorted.push(customers[each]);
      }
    }
    customersSorted.sort((a, b) => {
      return (a.unitsTotal - b.unitsTotal) * -1;
    });

    // Result
    const result = {
      unitsTotal: unitsInput.length,
      unitsTotalBillable: 0,
      unitsTotalNonbillable: 0,
      unitsPercentNonbillable: 0,
      unitsTotalDeactivated: unitsTotalDeactivated,
      unitsByCustomer: customers,
      unitsNonbillable: {},
      unitsByCustomerSorted: customersSorted,
      topCustomers: customersSorted.slice(0, 10)
    };

    // Separate nonbillable units
    for (let i = 0, l = nonBillableAccounts.length; i < l; i++) {
      const accountId = nonBillableAccounts[i];
      if (customers[accountId]) {
        result.unitsTotalNonbillable += customers[accountId].unitsTotal; // Need this check for accounts with 0 units.
        result.unitsNonbillable[accountId] = JSON.parse(JSON.stringify(customers[accountId]));
        delete customers[accountId];
      }
    }
    // Get total non-billable units
    result.unitsTotalBillable = result.unitsTotal - result.unitsTotalNonbillable;

    // Get percent non-billable
    result.unitsPercentNonbillable = Math.round(result.unitsTotalNonbillable / result.unitsTotal * 100 * 100) / 100; // Trick to get it to 2 decimal places

    return result;
  };

  const compareDatasets = (parsedA, parsedB, parsedC, parsedD) => {
    const result = {
      dataA: parsedA, // `dataA` is the newer data
      dataB: parsedB, // `dataB` is the older data
      dataC: parsedC, // `dataB` is the older data
      dataD: parsedD, // `dataB` is the older data
      delta: {}
    };
    result.delta = {
      unitsByCustomer: {},
      accountsAdded: {},
      accountsDeleted: {},
      unitsPercentNonbillableDelta: Math.round((result.dataA.unitsPercentNonbillable - result.dataB.unitsPercentNonbillable) * 100) / 100,
      unitsTotalDelta: result.dataA.unitsTotal - result.dataB.unitsTotal,
      unitsTotalBillableDelta: result.dataA.unitsTotalBillable - result.dataB.unitsTotalBillable,
      unitsTotalBillableAdded: 0,
      unitsTotalBillableDeleted: 0,
      unitsTotalNonbillableDelta: result.dataA.unitsTotalNonbillable - result.dataB.unitsTotalNonbillable // Don't need to itemize adds and deletions for nonbillable
    };

    const customerIdsA = Object.keys(result.dataA.unitsByCustomer);
    const customerIdsB = Object.keys(result.dataB.unitsByCustomer);
    const customerIds = Array.from(new Set([...customerIdsA, ...customerIdsB]));

    for (const i of customerIds) {
      const a = result.dataA.unitsByCustomer[i];
      const b = result.dataB.unitsByCustomer[i];
      const customerDelta = {
        accountId: i,
        accountName: "",
        unitsAdded: {},
        unitsDeleted: {},
        unitsTotalDelta: 0,
        unitsTotalAdded: 0,
        unitsTotalDeleted: 0
      };

      // Continuing customer, so get the delta
      if (a && b) {
        customerDelta.accountName = a.accountName;
        const unitIdsA = Object.keys(a.units);
        const unitIdsB = Object.keys(b.units);

        // Get units that were added
        for (const j of unitIdsA) {
          if (!unitIdsB.includes(j)) { // If current ID is NOT found in previous data, it's an addition (unless same UID).
            if (a.units[j].uid.length > 3) { // If UID value is long enough, test each unit to see if UIDs (ESNs) match.
              let sameUid = false;
              for (const k of unitIdsB) {
                //Check if device has same UID.
                if (a.units[j].uid === b.units[k].uid) sameUid = true;
              }
              if (sameUid === false) {
                customerDelta.unitsTotalAdded++;
                result.delta.unitsTotalBillableAdded++;
                customerDelta.unitsAdded[j] = a.units[j];
              }
            } else {
              customerDelta.unitsTotalAdded++;
              result.delta.unitsTotalBillableAdded++;
              customerDelta.unitsAdded[j] = a.units[j];
            }
          }
        }
        // Get units that were deleted
        for (const j of unitIdsB) {
          if (!unitIdsA.includes(j)) { // If current ID is NOT found in current data, it's a deletion.
            customerDelta.unitsTotalDeleted++;
            result.delta.unitsTotalBillableDeleted++;
            customerDelta.unitsDeleted[j] = b.units[j];
          }
        }
        customerDelta.unitsTotalDelta = customerDelta.unitsTotalAdded - customerDelta.unitsTotalDeleted;

        // New account. Delta is total units.
      } else if (a) {
        result.delta.accountsAdded[i] = a; // Add customer to accountsAdded

        customerDelta.accountName = a.accountName;
        customerDelta.unitsAdded = a.units;
        customerDelta.unitsTotalAdded = a.unitsTotal;
        customerDelta.unitsTotalDelta = customerDelta.unitsTotalAdded - customerDelta.unitsTotalDeleted;
        result.delta.unitsTotalBillableAdded += a.unitsTotal;

        // Deleted account. Delta is total units.
      } else {
        result.delta.accountsDeleted[i] = b; // Add customer to accountsAdded

        customerDelta.accountName = b.accountName;
        customerDelta.unitsDeleted = b.units;
        customerDelta.unitsTotalDeleted = b.unitsTotal;
        customerDelta.unitsTotalDelta = customerDelta.unitsTotalAdded - customerDelta.unitsTotalDeleted;
        result.delta.unitsTotalBillableDeleted += b.unitsTotal;
      }

      result.delta.unitsByCustomer[i] = customerDelta;
    }

    return result;
  };

  let parsedA = parseDataset(datasetA);
  let parsedB;
  let parsedC;
  let parsedD;

  if (datasetB) {
    parsedB = parseDataset(datasetB);
    result = compareDatasets(parsedA, parsedB);
  }
  if (datasetC) {
    parsedC = parseDataset(datasetC);
    result = compareDatasets(parsedA, parsedB, parsedC);
  }

  if (datasetD) {
    parsedD = parseDataset(datasetD);
    result = compareDatasets(parsedA, parsedB, parsedC, parsedD);
  }

  return result;
}
