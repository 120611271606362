import React, { useState, useEffect } from 'react';
import { Checkbox, Collapse, ListItem, ListItemIcon, ListItemText, makeStyles, ListSubheader } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ImportDestinationUnit from './ImportDestinationUnit';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(7),
    borderBottom: '1px solid lightgray',
    background: 'rgba(0, 0, 0, 0.04)'
  }
}))

const ImportDestinationAccount = ({ value, accounts, setAccounts, handleAccountCheckbox }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);

  useEffect(() => {
    if ( state === true ) return;
    for ( let account of accounts ) {
      if ( account.nm == value.nm ) {
        return setState(true);
      }
    }
  })
  
  const handleUnitCheckbox = (e, data) => {
    let copiedValue = JSON.parse(JSON.stringify(value));
    let newList = [];
    let itemAdded = false;
    if (accounts.length) {
      for (let item of accounts) {
        let copiedItem = JSON.parse(JSON.stringify(item))
        if (item.id === copiedValue.id) {
          let index;
          for (let key in copiedItem.unit) {
            if (copiedItem.unit[key].id === data.id) {
              index = key;
              break;
            };
          }
          if (index) {
            copiedItem.unit.splice(index, 1);
          } else {
            copiedItem.unit.push(data);
            setState(true);
          }
          newList.push(copiedItem);
          itemAdded = true;
          continue;
        }
        newList.push(copiedItem);
      }
      if (!itemAdded) {
        copiedValue.unit = [data];
        newList.push(copiedValue);
        setState(true);
      }
      setAccounts([...newList]);
    } else {
      copiedValue.unit = [data];
      setState(true);
      setAccounts([copiedValue]);
    }
  }

  const renderDropdownIcon = () => {
    if (value.unit === undefined || !value.unit.length) return;
    if (open) {
      return <ExpandLess />
    }
    return <ExpandMore />
  }

  return (
    <>
      <ListItem dense button={value.unit ? !!value.unit.length : false} onClick={() => setOpen(!open)} >
        <ListItemIcon>
          <Checkbox color="primary" checked={state} onClick={e => { setState(e.target.checked); handleAccountCheckbox(e, value) }} />
        </ListItemIcon>
        <ListItemText>{value.nm}</ListItemText>
        {renderDropdownIcon()}
      </ListItem>
      {value.unit && value.unit.length
        ? <Collapse in={open}>
          <ListSubheader disableSticky disableGutters className={classes.nested}>Unit Groups</ListSubheader>
          {value.unit.map(item => {
            return (
              <ImportDestinationUnit
                key={item.id}
                value={item}
                accounts={accounts}
                setAccounts={setAccounts}
                handleCheckbox={handleUnitCheckbox}
              />
            )
          }
          )}
        </Collapse>
        : null
      }
    </>
  )
}

export default ImportDestinationAccount;