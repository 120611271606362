import React, { useState } from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import ImportOption from './ImportOption';
import ImportDestination from './ImportDestination';
import CustomModal from '../../common/CustomModal';
import { apiPost } from '../../../lib/network';

const useStyles = makeStyles(theme => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  container: {
    padding: theme.spacing(4, 2, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  grid: {
    width: '100%',
    marginTop: 20
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  button: {
    marginTop: 35
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  label: {
    display: 'inline',
    marginRight: 10
  }
}))

const Import = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const snack = (msg, variant) => enqueueSnackbar(msg, { variant });
  const [modalStatus, setModalStatus] = useState(false);

  const [jobs, setJobs] = useState({ list: [], all: false });
  const [notifications, setNotifications] = useState({ list: [], all: false });
  const [reports, setReports] = useState({ list: [], all: false });
  const [accounts, setAccounts] = useState([]);

  const submitHandler = async () => {
    if (!accounts.length) return;

    const response = await apiPost(process.env.REACT_APP_API_SERVER, {
      cmd: 'wialon.importWlp',
      params: {
        jobs: jobs.list,
        notifications: notifications.list,
        reports: reports.list,
      },
      list: {
        accounts
      }
    });
    if (response) {
      if (response.success) {
        snack('Successfully imported the default template to the account(s)', 'success');
        setTimeout(() => window.location.reload(), 2000);
      } else {
        snack('Something went wrong importing the default template', 'error');
      }
    } else {
      snack('The request failed: Unable to make request.', 'error');
    }
  }

  return (
    <Grid style={{ margin: '0 0 35px' }}>
      <Grid container className={classes.container}>
        <Grid style={{ width: '100%' }}>
          <Typography variant="h4" align="center" display="block">Add Default Template</Typography>
        </Grid>
        <Typography variant="subtitle2" style={{ marginTop: 20 }}>Add default notifications, jobs, and reports template for new MiFleet accounts.</Typography>
      </Grid>
      <Grid container className={classes.grid} style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item sm={6} style={{ padding: 5, width: '100%' }}>
          <ImportOption
            jobs={jobs}
            setJobs={setJobs}
            notifications={notifications}
            setNotifications={setNotifications}
            reports={reports}
            setReports={setReports}
          />
        </Grid>
        <Grid item sm={6} style={{ padding: 5, width: '100%' }}>
          <ImportDestination
            accounts={accounts}
            setAccounts={setAccounts}
          />
        </Grid>
      </Grid>
      <Grid align="center">
        <Grid item sm={8} md={6}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={!((jobs.list.length || notifications.list.length || reports.list.length) && accounts.length)}
            className={classes.button}
            onClick={() => setModalStatus(true)}
          >
            Submit
          </Button>

          <CustomModal
            status={modalStatus}
            setStatus={setModalStatus}
            onSubmit={submitHandler}
            title={'Please Confirm To Add Default Template.'}
            renderMessage={() => {
              let notIncluded = [];
              for (let item of accounts) {
                if (!item.unit.length) {
                  notIncluded.push(item.nm);
                }
              }

              if (!notIncluded.length) return;
              return (
                < Grid item style={{ margin: '10px 0 10px' }} >
                  <Typography gutterBottom variant="subtitle2">The following account(s) will not include the notifications template: <strong>{notIncluded.join(', ')}</strong></Typography>
                  <Typography color="error" variant="subtitle2">Please note if the account has existing templates with the same name, those templates will be deleted and the new templates will be added.</Typography>
                </Grid>
              )
            }
            } />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Import;