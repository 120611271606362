import React from 'react';
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  grid: {
    width: '100%',
    marginTop: 20
  },
  label: {
    marginBottom: 10
  },
  color: {
    color: 'red'
  },
  [theme.breakpoints.down('sm')]: {
    checkboxPadding: {
      paddingLeft: 0
    }
  },
  [theme.breakpoints.up('md')]: {
    box: {
      display: 'inline-flex',
      position: 'absolute',
      alignItems: 'center',
      marginTop: 10
    }
  }
}))

const AccountSetting = ({ setting, setSetting, error, setError }) => {
  const classes = useStyles();
  const { measurement, timezone, dst } = setting;
 
  return (
    <>
      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Measurement System </InputLabel>
        <Select id="metric" variant="outlined" fullWidth value={measurement} onChange={e => setSetting({ ...setting, measurement: e.target.value })}>
          <MenuItem value={0}>Metric</MenuItem>
          <MenuItem value={1}>US</MenuItem>
          <MenuItem value={2}>Imperial</MenuItem>
        </Select>
      </Grid>

      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Timezone <span className={classes.color}>(required)</span></InputLabel>
        <FormControl error={error.timezone} fullWidth>
          <Select
            id="timezone"
            variant="outlined"
            displayEmpty
            value={timezone}
            onChange={e => {
              setError({ ...error, timezone: !e.target.value })
              setSetting({ ...setting, timezone: e.target.value })
            }}
          >
            <MenuItem value="" disabled>Choose</MenuItem>
            <MenuItem value="-28800"><strong>Pacific Time (US & Canada)</strong></MenuItem>
            <MenuItem value="-25200"><strong>Mountain Time (US & Canada)</strong></MenuItem>
            <MenuItem value="-21600"><strong>Central Time (US & Canada), Guadalajara, Mexico city</strong></MenuItem>
            <MenuItem value="-18000"><strong>Eastern time (US & Canada)</strong></MenuItem>
            <MenuItem value="-43200">International Date Line West</MenuItem>
            <MenuItem value="-39600">Midway Island, Samoa</MenuItem>
            <MenuItem value="-36000">Hawaii</MenuItem>
            <MenuItem value="-34200">French Polynesia, Marquesas Islands</MenuItem>
            <MenuItem value="-32400">Alaska</MenuItem>
            <MenuItem value="-16200">Venezuela</MenuItem>
            <MenuItem value="-14400">Atlantic time (Canada), Manaus, Santiago</MenuItem>
            <MenuItem value="-12600">Newfoundland</MenuItem>
            <MenuItem value="-10800">Greenland, Brasilia, Montevideo</MenuItem>
            <MenuItem value="-7200">Mid-Atlantic</MenuItem>
            <MenuItem value="-3600">Azores</MenuItem>
            <MenuItem value="0">GMT: Dublin, Edinburgh, Lisbon, London</MenuItem>
            <MenuItem value="3600">Amsterdam, Berlin, Rome, Vienna, Prague, Brussels</MenuItem>
            <MenuItem value="7200">Athens, Istanbul, Beirut, Cairo, Jerusalem</MenuItem>
            <MenuItem value="10800">St. Petersburg, Minsk, Baghdad, Moscow</MenuItem>
            <MenuItem value="12600">Iran</MenuItem>
            <MenuItem value="14400">Volgograd, Baku, Yerevan</MenuItem>
            <MenuItem value="16200">Afghanistan</MenuItem>
            <MenuItem value="18000">Yekaterinburg, Tashkent</MenuItem>
            <MenuItem value="19800">Chennai, Kolkata, Mumbai, New Delhi</MenuItem>
            <MenuItem value="20700">Nepal</MenuItem>
            <MenuItem value="21600">Omsk, Almaty</MenuItem>
            <MenuItem value="23400">Myanmar, Cocos Islands</MenuItem>
            <MenuItem value="25200">Indochina Time (ICT)</MenuItem>
            <MenuItem value="28800">Krasnoyarsk, Ulaan Bataar, Perth</MenuItem>
            <MenuItem value="32400">Irkutsk</MenuItem>
            <MenuItem value="34200">Australian Central Standard Time</MenuItem>
            <MenuItem value="36000">Yakutsk, Canberra, Melbourne, Sydney, Hobart</MenuItem>
            <MenuItem value="37800">Lord Howe Standard Time</MenuItem>
            <MenuItem value="39600">Vladivostok, Solomon Is., New Caledonia</MenuItem>
            <MenuItem value="41400">Norfolk Islan</MenuItem>
            <MenuItem value="43200">Magadan, Auckland, Wellington</MenuItem>
            <MenuItem value="45900">New Zealand, Chatham Island</MenuItem>
            <MenuItem value="46800">Nuku'alofa</MenuItem>
            <MenuItem value="50400">Kiribati, Line Islands</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Daylight Savings Time (DST)</InputLabel>
        <Select id="daylight" variant="outlined" fullWidth value={dst} onChange={e => setSetting({ ...setting, dst: e.target.value })}>
          <MenuItem value="0x08000000">None</MenuItem>
          <MenuItem value="0x0A010000"><strong>United States, Canada</strong></MenuItem>
          <MenuItem value="0x0A050000">Russia</MenuItem>
          <MenuItem value="0x0B030000">Europe</MenuItem>
          <MenuItem value="0x0A040000">Jordan</MenuItem>
          <MenuItem value="0x0A060000">Syria</MenuItem>
          <MenuItem value="0x0A070000">Mexico (PSST), Turks & Caicos Islands</MenuItem>
          <MenuItem value="0x0A080000">Cuba</MenuItem>
          <MenuItem value="0x0A090000">Egypt</MenuItem>
          <MenuItem value="0x0A0B0000">Iraq</MenuItem>
          <MenuItem value="0x0A0C0000">Iran</MenuItem>
          <MenuItem value="0x0A200000">Falkland Islands</MenuItem>
          <MenuItem value="0x0A210000">Namibia</MenuItem>
          <MenuItem value="0x0A220000">New Zealand</MenuItem>
          <MenuItem value="0x0A230000">Chile</MenuItem>
          <MenuItem value="0x0A240000">Brazil</MenuItem>
          <MenuItem value="0x0A250000">Paraguay</MenuItem>
          <MenuItem value="0x0A270000">Australia</MenuItem>
          <MenuItem value="0x0A280000">Tasmania</MenuItem>
          <MenuItem value="0x0A290000">Fiji</MenuItem>
        </Select>
      </Grid>
    </>
  )
}

export default AccountSetting;