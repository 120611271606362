import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: 54,
    borderRadius: 5,
    border: '1px solid #9C9C9C'
  }
}));

const CustomSkeleton = ({ style, number}) => {
  const classes = useStyles();

  const renderSkeleton = () => {
    if ( !number ) number = 1;
    let skeleton = [];

    for (let i = 0; i < number; i++) {
      skeleton.push(<Skeleton key={i} className={classes.root} style={style} variant="rect" animation="wave" />)
    }
    return skeleton;
  }

  return (
    // <Skeleton className={classes.root} style={style} variant="rect" animation="wave" />
    <>
      {renderSkeleton()}
    </>

  )
}

export default CustomSkeleton;
