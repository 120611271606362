import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, Collapse, ListItem, ListItemIcon, ListItemText, makeStyles, ListSubheader } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExportItemToUnit from './ExportItemToUnit';
import { ExportItemContext } from '../../context/ExportItemContext';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(7),
    borderBottom: '1px solid lightgray',
    background: 'rgba(0, 0, 0, 0.04)'
  }
}))

const ExportItemToAccount = ({ account }) => {
  const classes = useStyles();
  const { importAccountList, setImportAccountList } = useContext(ExportItemContext);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);

  useEffect(() => {
    setState( !!importAccountList[account.crt] );
  }, [importAccountList])

  const renderDropdownIcon = () => {
    if (!account.unit.length) return;
    if (open) {
      return <ExpandLess />
    }
    return <ExpandMore />
  }

  const checkboxHandler = (e, addedFromUnit) => {
    e.stopPropagation();
    const copiedAccount = JSON.parse(JSON.stringify(account))
    if (state) {
      setImportAccountList(prevState => {
        delete prevState[copiedAccount.crt];
        return { ...prevState }
      })
      setState(false);
    } else {
      setImportAccountList(prevState => {
        if (addedFromUnit) {
          let updatedList = copiedAccount.unit.filter(item => {
            return item.id === addedFromUnit
          });
          copiedAccount.unit = updatedList;
        }
        prevState[copiedAccount.crt] = copiedAccount;
        return { ...prevState }
      })
      setState(true);
    }
  }

  return (
    <>
      <ListItem dense button={account.unit ? !!account.unit.length : false} onClick={e => setOpen(!open)} >
        <ListItemIcon>
          <Checkbox color="primary" checked={state} onClick={checkboxHandler} />
        </ListItemIcon>
        <ListItemText>{account.nm}</ListItemText>
        {renderDropdownIcon()}
      </ListItem>
      {account.unit && account.unit.length
        ? <Collapse in={open}>
          <ListSubheader disableSticky disableGutters className={classes.nested}>Unit Groups</ListSubheader>
          {account.unit.map(item => {
            return (
              <ExportItemToUnit
                key={item.id}
                unit={item}
                parentAccountId={account.crt}
                parentCheckboxHandler={checkboxHandler}
              />
            )
          }
          )}
        </Collapse>
        : null
      }
    </>
  )
}

export default ExportItemToAccount;