import React, { useState, useEffect } from 'react';
import { Checkbox, Collapse, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExportItemSubCategory from './ExportItemSubCategory';

const ExportItemCategory = ({ value, option, setOption }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);

  useEffect(() => {
    for (let item of option ) {
      if ( item.category === value.category ) {
        setState(true);
        return;
      }
    }
  })

  const updateCategoryName = () => {
    //rep - reports, ujb - jobs, unf - notifications, zl - geofences
    switch (value.category) {
      case 'rep':
        return 'Reports';
      case 'ujb':
        return 'Jobs';
      case 'unf':
        return 'Notifications';
      case 'zl':
        return 'Geofences';
      default:
        return value.category;
    }
  }

  const categoryClickHandler = e => {
    e.stopPropagation();
    let container = {};
    if (e.target.checked) {
      container.category = value.category;
      container.list = value.list;
      setOption([...option, container]);
      setState(true);
    } else {
      const updatedList = option.filter(item => item.category !== value.category);
      setOption(updatedList);
      setState(false);
    }
  }

  return (
    <>
      <ListItem dense button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <Checkbox color="primary" checked={state} onClick={categoryClickHandler} />
        </ListItemIcon>
        <ListItemText>{updateCategoryName()}</ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open}>
        { value.list && value.list.map(item => (
          <ExportItemSubCategory key={item.n} value={item} category={value.category} option={option} setOption={setOption} parent={value} setMainCheckState={setState} />
        ))}
      </Collapse>
    </>
  )
}
export default ExportItemCategory;