import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid, InputLabel, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { apiPost } from '../../lib/network';
import CustomSkeleton from '../common/CustomSkeleton';

const useStyles = makeStyles( theme => ({
  container: {
    width: '100%',
    marginTop: 10,
  },
  grid: {
    width: '100%',
    marginTop: 20
  },
  label: {
    marginBottom: 10
  },
  color: {
    color: 'red'
  },
  notchedOutline: {
    borderColor: 'red !important'
  }
}))

const AccountDetail = ({ detail, setDetail, error, setError, isUnique, setIsUnique }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState({ dealer: true, billing: false });
  const [dealerList, setDealerList] = useState([]);
  const [billingPlanList, setBillingPlanList] = useState([]);
  const [usernameCheckbox, setUsernameCheckbox] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    const getDealers = async () => {
      const response = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: "wialon.getDealers" });
      if (response.success && !isCancelled) {
        const list = response.result.items;
        const adminDealers = list.filter(item => item.fl === 36 || item.fl === 34 || item.fl === 26 || item.fl === 24 || item.fl === 16 || item.fl === 14 || item.fl === 6 || item.fl === 4 );
        adminDealers.push(list[list.length - 1]) // Last item should always be the logged in account
        setDealerList(adminDealers || []);
      } else {
        console.error(response);
      }
      setIsLoading(prevState => {
        return { dealer: false, billing: prevState.billing }
      })
    }

    getDealers();

    return () => { isCancelled = true }
  }, [])

  useEffect(() => {
    let isCancelled = false;
    if (!detail.accountCreator && !isCancelled) {
      return setIsLoading(prevState => {
        return { dealer: prevState.dealer, billing: false }
      })
    }

    setIsLoading(prevState => {
      return { dealer: prevState.dealer, billing: true }
    });

    const getBillingPlans = async () => {
      let isCancelled = false;
      const response = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: "wialon.getBillingPlan", params: { accountId: detail.accountCreator } });

      if (response.success && response.result[detail.accountCreator] && !isCancelled) {
        setBillingPlanList(response.result[detail.accountCreator].subPlans || []);
      } else {
        console.error(response);
      }
      setIsLoading(prevState => {
        return { dealer: prevState.dealer, billing: false }
      })
    }

    getBillingPlans();

    return () => { isCancelled = true }
  }, [detail.accountCreator])

  const validateHandler = async (value, type) => {
    if (!value) return;
    const checkUnique = await apiPost(process.env.REACT_APP_API_SERVER, {
      cmd: 'wialon.checkUnique',
      params: {
        name: value,
        type
      }
    });

    if (checkUnique && checkUnique.result && checkUnique.result.success) { // 0 - unique, 1 - exist
      if (type === 'user') {
        if (checkUnique.result.response) {
          setError({ ...error, accountUser: !!value });
        }
        setIsUnique({ ...isUnique, accountUsername: !checkUnique.result.response })
      } else {
        if (checkUnique.result.response) {
          setError({ ...error, companyName: !!value });
        }
        setIsUnique({ ...isUnique, accountName: !checkUnique.result.response })
      }
    }
  }

  const handleInput = e => {
    const target = e.currentTarget.name;
    const value = e.currentTarget.value;
    if (target === 'company') {
      if (error.companyName) {
        setError({ ...error, companyName: !value });
        setIsUnique({ ...isUnique, accountName: !!value });
      };
      setDetail({ ...detail, company: value });
    } else if (target === 'email') {
      if (error.accountEmail) setError({ ...error, accountEmail: !value });
      if (usernameCheckbox) {
        if ( error.accountUser ) {
          setIsUnique({ ...isUnique, accountUsername: !!value });
        }
        setDetail({ ...detail, email: value, username: value });
      } else {
        setDetail({ ...detail, email: value })
      }
    } else if (target === 'user') {
      if (error.accountUser) {
        setError({ ...error, accountUser: !value });
        setIsUnique({ ...isUnique, accountUsername: !!value });
      }
      setDetail({ ...detail, username: value })
    }
  }

  const handleAutocompleteChange = (e, v) => {
    if (v) {
      if (e.currentTarget.id.indexOf('creator') !== -1) {
        if (error.creatorId) setError({ ...error, creatorId: !v.id });
        setDetail({ ...detail, accountCreator: v.id })
      } else if (e.currentTarget.id.indexOf('billing') !== -1) {
        if (error.billingPlan) setError({ ...error, billingPlan: !v });
        setDetail({ ...detail, billingPlan: v })
      }
    }
  }

  const handleCheckbox = e => {
    const isChecked = e.currentTarget.checked;
    let username = '';
    if (isChecked) {
      username = detail.email;
      validateHandler(username, 'user');
    } else {
      if (error.accountUser) setError({ ...error, accountUser: true });
      setIsUnique({ ...isUnique, accountUsername: !detail.username });
    }

    setDetail({ ...detail, username })
    setUsernameCheckbox(!usernameCheckbox);
  }

  return (
    <Grid className={classes.container}>
      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Account Name <span className={classes.color}>(required)</span></InputLabel>
        <TextField
          fullWidth
          id="accountName"
          name="company"
          variant="outlined"
          error={error.companyName}
          value={detail.company}
          onChange={handleInput}
          onBlur={() => validateHandler(detail.company, 'avl_resource')}
          helperText={isUnique.accountName ? "Account name must be between 4 and 50 characters." : "Account name already exists."}
        />
      </Grid>

      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Account Email <span className={classes.color}>(required)</span></InputLabel>
        <TextField
          fullWidth
          id="accountEmail"
          name="email"
          type="email"
          variant="outlined"
          error={error.accountEmail}
          value={detail.email}
          onChange={handleInput}
          onBlur={() => validateHandler(detail.username, 'user')}
        />
      </Grid>

      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Account Username <span className={classes.color}>(required)</span></InputLabel>
        <TextField
          fullWidth
          id="accountUser"
          name="user"
          variant="outlined"
          disabled={usernameCheckbox}
          error={error.accountUser}
          value={usernameCheckbox ? detail.email : detail.username}
          onChange={handleInput}
          onBlur={() => validateHandler(detail.username, 'user')}
          helperText={isUnique.accountUsername ? "" : "Account username already exists"}
          InputProps={ !isUnique.accountUsername ? {
            classes: {
              notchedOutline: classes.notchedOutline,
            },
            inputMode: "numeric"
          } : null}
        />
        <FormControlLabel
          control={
            <Checkbox
              id="accountUserCheckbox"
              checked={usernameCheckbox}
              onChange={handleCheckbox}
              size="small"
              color="primary"
            />}
          label="Same as above"
        />
      </Grid>
      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Account Creator <span className={classes.color}>(required)</span></InputLabel>
        {!isLoading.dealer
          ? <Autocomplete
            id="creator"
            options={dealerList}
            getOptionLabel={options => options.nm}
            disableClearable
            disabled={!dealerList.length}
            onChange={handleAutocompleteChange}
            renderOption={option => <span name="creator">{option.nm}</span>}
            renderInput={params => <TextField
              {...params}
              error={error.creatorId}
              variant="outlined"
              placeholder={!dealerList.length ? 'No items found' : 'Choose'}
            />}
          />
          : <CustomSkeleton />}
      </Grid>

      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Billing Plan <span className={classes.color}>(required)</span></InputLabel>
        {!isLoading.billing
          ? <Autocomplete
            id="billing"
            options={billingPlanList}
            getOptionLabel={options => options}
            disableClearable
            onChange={handleAutocompleteChange}
            renderOption={option => <span>{option}</span>}
            renderInput={params => <TextField
              {...params}
              error={error.billingPlan}
              variant="outlined"
              placeholder={!billingPlanList.length ? 'No items found' : 'Choose'}
            />}
          />
          : <CustomSkeleton />
        }
      </Grid>
    </Grid>
  )
}

export default AccountDetail;