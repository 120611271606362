import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const BarChartContainer = ({ data, legend = false, barDataKey = [], stacked }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} stackOffset="sign">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis type="number" />
        {legend ? <Legend /> : null}
        <Tooltip />
        {barDataKey.map((key, index) => <Bar key={index} dataKey={key.name || key} stackId={stacked ? 'x' : ''} fill={key.color || "rgb(163,188,249)"} />)}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarChartContainer;