import React, { useEffect } from 'react';
import { Grid, InputLabel, TextField } from '@material-ui/core';

import { apiPost } from '../../lib/network';

const SalesOrder = ({ scanlist, setScanlist, orderNum, setOrderNum }) => {

  useEffect(() => {
    let isCancelled = false;
    if (orderNum.length < 4 && scanlist.length && !isCancelled) return setScanlist([]);
    if (orderNum.length < 4) return;

    const fetchSalesOrder = async () => {
      try {
        const response = await apiPost(process.env.REACT_APP_API_SERVER, {
          cmd: "scanTool.getDevicesFiltered",
          params: { SO: orderNum }
        });

        if (response.success && response.result.devicesArr.length && !isCancelled) {
          const deviceByScanList = seperateByScanlistID(response.result.devicesArr);
          setScanlist(deviceByScanList);
        } else {
          if ( !isCancelled ) {
            setScanlist([])
          }
        }
      } catch (err) {
        console.error(err)
      }
    }

    const seperateByScanlistID = deviceList => {
      const scanlistArr = [];
      const indexOfScanlist = []
      for (let device of deviceList) {
        if (indexOfScanlist.indexOf(device.scanlist_id) === -1) {
          let model = {}
          model.id = device.scanlist_id;
          model.data = [device];
          indexOfScanlist.push(device.scanlist_id);
          scanlistArr.push(model);
        } else {
          const index = indexOfScanlist.indexOf(device.scanlist_id);
          scanlistArr[index].data.push(device);
        }
      }
      return scanlistArr
    }
    fetchSalesOrder();

    return () => {
      isCancelled = true;
    }
  }, [orderNum, setScanlist, scanlist.length])

  return (
    <Grid item style={{ width: '100%', marginTop: 20 }}>
      <InputLabel style={{ marginBottom: 10 }}>Sales Order</InputLabel>
      <TextField
        placeholder="Sales Order"
        variant="outlined"
        fullWidth
        value={orderNum}
        onChange={e => setOrderNum(e.currentTarget.value)}
      />
    </Grid>
  )
}

export default SalesOrder;