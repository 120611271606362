import React, { useState, useEffect, useContext } from 'react';
import { Grid, makeStyles, Typography, InputLabel, Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AppContext } from '../../AppContext';

import { apiPost } from '../../lib/network';
import CustomSkeleton from '../common/CustomSkeleton';

const deviceList = [
  { id: 400001301, uid2: 1, name: "ATrack AK11", tp: "21572", up: "21572" },
  { id: 400009093, uid2: 1, name: "ATrack AS11", tp: "21776", up: "21776" },
  { id: 400009092, uid2: 1, name: "ATrack AX11", tp: "21775", up: "21775" },
  { id: 400001179, uid2: 1, name: "ATU-620", tp: "0", up: "20920" },
  { id: 400000591, uid2: 0, name: "DCS Gateway", tp: "21548", up: "0" },
  { id: 400000471, uid2: 1, name: "LMU-2600 Series", tp: "20785", up: "20785" },
  { id: 400000758, uid2: 1, name: "LMU-2820", tp: "20761", up: "20761" },
  { id: 400001154, uid2: 1, name: "LMU-3030", tp: "20933", up: "20933" },
  { id: 400000403, uid2: 1, name: "LMU-4200 Series", tp: "20515", up: "20515" },
  { id: 400000135, uid2: 1, name: "LMU-700 Series", tp: "20585", up: "20585" },
  { id: 400000192, uid2: 0, name: "MiTrak", tp: "20963", up: "0" },
  { id: 400000632, uid2: 0, name: "Streamax X3-H0402", tp: "21380", up: "0" },
  { id: 400000203, uid2: 1, name: "TTU-1200 Series", tp: "0", up: "20923" },
  { id: 400000605, uid2: 1, name: "TTU-2820 Series", tp: "0", up: "20924" },
  { id: 400001014, uid2: 1, name: "TTU-700 Series", tp: "0", up: "20922" },
  { id: 400001015, uid2: 0, name: "whereQube OBD", tp: "21515", up: "21515" }
];

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    margin: theme.spacing(1, 0)
  },
  gridContainer: {
    margin: '15px 0 0'
  },
  grid: {
    marginTop: 15
  },
  color: {
    color: 'red'
  }
}))

const Platform = ({ deviceAccount, setDeviceAccount, setUnitGroup, setHardwareType, platform, usc, setUsc, setPlatform, error, setError }) => {
  const classes = useStyles();
  const { appState } = useContext(AppContext);
  const [loading, setLoading] = useState({ device: true, unit: false, hardware: true })
  const [device, setDevice] = useState([]);
  const [unit, setUnit] = useState([]);
  const [hardware, setHardware] = useState([]);

  useEffect(() => {
    let isCancelled = false;

    const getAccountsAll = async () => {
      try {
        const response = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: 'wialon.getAccountsAll' });
        if (response.success && !isCancelled) {
          setDevice(response.result.items)
        }
      } catch (err) {
        console.error(err);
      }
      if (!isCancelled) {
        setLoading(prevState => {
          return { device: false, unit: prevState.unit, hardware: prevState.hardware }
        })
      }
    }
    getAccountsAll();

    return () => {
      isCancelled = true;
    }
  }, [])

  useEffect(() => {
    let isCancelled = false;

    const getHardwareTypes = async () => {
      try {
        const response = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: 'wialon.getHardwareTypes' });
        if (response.success && !isCancelled) {
          setHardware(response.result);
        }
      } catch (err) {
        console.error(err);
      }
      if (!isCancelled) {
        setLoading(prevState => {
          return { device: prevState.device, unit: prevState.unit, hardware: false }
        })
      }
    }

    if (appState.auth.user === "miplatform") {
      getHardwareTypes();
    } else {
      if (!isCancelled) {
        setHardware(deviceList);
        setLoading(prevState => {
          return { device: prevState.device, unit: prevState.unit, hardware: false }
        })
      }
    }

    return () => {
      isCancelled = true;
    }
  }, [])

  useEffect(() => {
    if (!deviceAccount) return;
    let isCancelled = false;

    const getUnitGroup = async (id) => {
      try {
        const response = await apiPost(process.env.REACT_APP_API_SERVER, {
          cmd: "wialon.getUnitgroupsInAccount",
          params: {
            creatorId: id
          }
        });
        if (response.success) {
          if (response.result.items && !isCancelled) {
            setUnit(response.result.items);
          }
        }
      } catch (err) {
        console.error(err);
      }
      if (!isCancelled) {
        setLoading(prevState => {
          return { device: prevState.device, unit: false, hardware: prevState.hardware }
        })
      }
    }
    getUnitGroup(Number(deviceAccount));

  }, [deviceAccount])

  const handleDeviceAccount = (e, v) => {
    if (error.creatorId) setError({ ...error, creatorId: !("" + v.crt) });
    setDeviceAccount("" + v.crt);
    setLoading({ ...loading, unit: true });
    setUnitGroup('');
  }

  const renderByPlatform = () => {
    if (platform === 'mifleet') {
      return (
        <Grid item className={classes.gridContainer}>
          <Grid item className={classes.grid}>
            <InputLabel style={{ marginBottom: 10 }}>Device Owner Account <span className={classes.color}>(required)</span></InputLabel>
            {!loading.device
              ? <Autocomplete
                options={device}
                getOptionLabel={option => option.nm}
                disableClearable
                disabled={!device.length}
                onChange={handleDeviceAccount}
                renderOption={option => <span>{option.nm}</span>}
                renderInput={params => <TextField
                  {...params}
                  variant="outlined"
                  error={error.creatorId}
                  placeholder={!device.length ? 'Unable to fetch request' : 'Choose'}
                />}
              />
              : <CustomSkeleton />
            }

          </Grid>

          <Grid item className={classes.grid}>
            <InputLabel style={{ marginBottom: 10 }}>Unit Group</InputLabel>
            {!loading.unit
              ? <Autocomplete
                options={unit}
                getOptionLabel={option => option.nm}
                onChange={(e, v) => v ? setUnitGroup("" + v.id) : setUnitGroup("")}
                renderOption={option => <span>{option.nm}</span>}
                renderInput={params => <TextField
                  {...params}
                  variant="outlined"
                  placeholder={!deviceAccount ? '' : (!unit.length && !loading.unit && deviceAccount) ? 'No items found' : 'Choose'}
                />}
              />
              : <CustomSkeleton />
            }
            {appState.auth.user === "miplatform"
              && <Grid style={{ display: 'flex', alignItems: 'center' }} >
                  <Typography display="inline" align="justify">Add to USC group. <mark style={{ borderRadius: 2 }}>Check for USC accounts.</mark></Typography>
                  <Checkbox size="small" color="primary" checked={usc} onChange={() => setUsc(!usc)} />
                </Grid>
            }
          </Grid>
          <Grid item className={classes.grid}>
            <InputLabel style={{ marginBottom: 10 }}>Device Model <span className={classes.color}>(required)</span></InputLabel>
            {!loading.hardware
              ? <Autocomplete
                options={hardware}
                getOptionLabel={option => option.name}
                disableClearable
                disabled={!hardware.length}
                onChange={(e, v) => {
                  if (error.hwTypeId || error.deviceType) setError({ ...error, deviceType: !v.name, hwTypeId: !("" + v.id) });
                  setHardwareType({ id: "" + v.id, name: v.name })
                }}
                renderOption={option => <span>{option.name}</span>}
                renderInput={params => <TextField
                  {...params}
                  variant="outlined"
                  error={error.deviceType || error.hwTypeId}
                  placeholder={!hardware.length ? 'Unable to fetch request' : 'Choose'}
                />}
              />
              : <CustomSkeleton />
            }
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Grid item style={{ width: '100%' }}>
      {/* Commented out until we get more information about flespi */}
      {/* <InputLabel style={{ marginBottom: 10 }}>Platform</InputLabel>
      <Select
        fullWidth
        disabled
        variant="outlined"
        value={platform}
        onChange={e => setPlatform(e.target.value)}
      >
        <MenuItem value="mifleet">MiFleet</MenuItem>
      </Select> */}
      <Grid item>
        {renderByPlatform()}
      </Grid>
    </Grid>
  )
}

export default Platform;