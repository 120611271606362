import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button, InputLabel, TextField, Grid, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    marginTop: 20
  },
  grid: {
    width: '100%',
    marginTop: 10
  },
  label: {
    marginBottom: 10
  },
  color: {
    color: 'red'
  }
}))

const DeviceList = ({ scanlist, orderNum, deviceList, setDeviceList, error, setError, appState }) => {
  const classes = useStyles();
  const [state, setState] = useState('list');
  const [scanlistItems, setScanlistItems] = useState([]);

  useEffect(() => {
    if (state === 'manual') return;
    let isCancelled = false;

    const seperateScanlistData = () => {
      let list = [];
      if (scanlist.length) { //If scanlist exists, add each scanlist id to menu list
        for (let devices of scanlist) {
          const esnArr = devices.data.map(device => device.esn);
          list.push({
            id: devices.id,
            esn: esnArr,
            message: `Scan ${devices.id}: qty ${devices.data.length} ${devices.data[0].part_number}`
          })
        }
      }
      if (!isCancelled) setScanlistItems(list)
    }
    seperateScanlistData();

    return () => {
      isCancelled = true;
    }
  }, [scanlist, orderNum, state]);

  const handleDeviceInput = e => {
    const deviceListItem = e.currentTarget.value.split('\n');
    if (error.deviceList) setError({ ...error, deviceList: !deviceListItem.length });
    setDeviceList(deviceListItem);
  }

  const handleButtonClick = e => {
    setDeviceList([]);
    setState(e.currentTarget.name);
  }

  const renderInputField = () => {
    if (state === 'manual' || ( state === 'list' && appState.auth.user !== 'miplatform')) {
      return (
        <Grid item className={classes.grid}>
          <TextField
            fullWidth
            multiline
            rows="6"
            variant="outlined"
            error={error.deviceList}
            placeholder="Enter one device ID on each line."
            onChange={handleDeviceInput}
          />
        </Grid>
      )
    }
    if (state === 'list' && appState.auth.user === 'miplatform') {
      return (
        <Grid item className={classes.grid}>
          <InputLabel shrink style={{ marginBottom: 5 }}>Choose scan list</InputLabel>
          <Autocomplete
            options={scanlistItems}
            getOptionLabel={option => option.message}
            disableClearable
            onChange={(e, v) => {
              if (error.deviceList) setError({ ...error, deviceList: !v.esn.length });
              setDeviceList(v.esn);
            }}
            renderOption={option => <span>{option.message}</span>}
            renderInput={params => <TextField
              {...params}
              error={error.deviceList}
              variant="outlined"
              placeholder={!scanlistItems.length && orderNum.length > 3 ? `Nothing found for SO# ${orderNum}` : !scanlistItems.length ? '' : 'Choose'}
            />}
          />
        </Grid>
      )
    }
  }

  return (
    <Grid item className={classes.container}>
      <InputLabel className={classes.label}>Device List <span className={classes.color}>(required)</span></InputLabel>
      {appState.auth.user === 'miplatform'
        ? <ButtonGroup fullWidth>
          <Button
            color="primary"
            name="list"
            variant={state === 'list' ? 'contained' : 'outlined'}
            onClick={handleButtonClick}
          >
            Choose Scan List
        </Button>
          <Button
            color="primary"
            name="manual"
            variant={state === 'manual' ? 'contained' : 'outlined'}
            onClick={handleButtonClick}
          >
            Enter Manually
        </Button>
        </ButtonGroup>
        : null
      }
      <Grid item>
        {renderInputField()}
      </Grid>
    </Grid>

  )
}

export default DeviceList;