import React, { useEffect, useState } from 'react';
import { Grid, InputLabel, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { apiPost } from '../../lib/network';
import CustomSkeleton from '../common/CustomSkeleton';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    marginTop: 20
  },
  grid: {
    width: '100%',
    marginTop: 20
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  label: {
    display: 'inline',
    marginRight: 10
  }
}))

const AdditionalSetting = ({ setting, setSetting, dealerId, error, setError }) => {
  const classes = useStyles();
  const [subAccounts, setSubAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!dealerId) return; //Reject if dealerId is empty, don't want to fetch request
    let isCancelled = false;
    // Reset sub accounts when new account creator is chosen
    setSetting({ ...setting, subAccount: '' });

    const fetchGeofenceSubAccount = async () => {
      const response = await apiPost(process.env.REACT_APP_API_SERVER, {
        cmd: 'wialon.getSubAccounts',
        params: {
          creatorId: dealerId
        }
      });
      if (response.success && !isCancelled) {
        const filteredList = response.result.items.filter(item => item.nm.indexOf('Geofences') !== -1 || item.nm.indexOf('geofences') !== -1);
        setSubAccounts(filteredList);
      }
      setIsLoading(false);
    }

    setIsLoading(true);
    fetchGeofenceSubAccount();
  }, [dealerId])

  const emailHandler = e => {
    const value = e.currentTarget.value;
    if (error.companyEmail) setError({ ...error, companyEmail: !value });
    setSetting({ ...setting, welcomeEmail: value.split(',') });
  }

  return (
    <Grid item className={classes.container}>
      <Typography variant="h5">Optional Settings</Typography>
      <Grid item className={classes.grid}>
        <Grid item className={classes.inputContainer}>
          <InputLabel className={classes.label}>Welcome Email</InputLabel>
          <Tooltip disableFocusListener disableTouchListener title="Welcome email will go to the email address above, please include any additional email address below ." placement="right">
            <HelpOutlineIcon fontSize="small" />
          </Tooltip>
        </Grid>

        <TextField
          fullWidth
          id="welcomeEmail"
          name="text"
          variant="outlined"
          placeholder="email@address.com,email@address.com"
          value={setting.welcomeEmail}
          onChange={emailHandler}
        />
      </Grid>

      <Grid item className={classes.grid}>
        <Grid item className={classes.inputContainer}>
          <InputLabel className={classes.label}>Geofence Access</InputLabel>
          <Tooltip disableFocusListener disableTouchListener title="This will give additional read-only rights to a shared resource. This is frequently used when sharing a resource with specific report templates with multiple accounts.For dealer accounts, this may be a subaccount of the dealer. " placement="right">
            <HelpOutlineIcon fontSize="small" />
          </Tooltip>
        </Grid>
        {!isLoading
          ? <Autocomplete
            id="geofence"
            options={subAccounts}
            getOptionLabel={options => options.nm}
            disableClearable
            disabled={!subAccounts.length}
            onChange={(e, v) => setSetting({ ...setting, subAccount: "" + v.id })}
            renderOption={(option) => <span name="creator">{option.nm}</span>}
            renderInput={params => <TextField
              {...params}
              variant="outlined"
              placeholder={!subAccounts.length ? 'No items found' : 'Choose'}
            />}
          />
          : <CustomSkeleton />
        }
      </Grid>
    </Grid>
  )
}

export default AdditionalSetting;