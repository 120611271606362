import React, { useState, useContext } from 'react';
import { Button, TextField, Typography, makeStyles, Grid, Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { AppContext } from '../../AppContext';
import CustomSkeleton from '../common/CustomSkeleton';
import { apiPost } from '../../lib/network';

const useStyles = makeStyles(theme => ({
  centerGrid: {
    margin: '0 auto'
  },
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 50,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    width: '75%'
  },
  button_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    width: 200
  },
  seperator: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    width: 300,
    padding: '15px 0',
    margin: 0,
    '&::before, &::after': {
      content: '""',
      flex: 1,
      borderBottom: '1px solid black'
    },
    '&::before': {
      marginLeft: 55
    },
    '&::after': {
      marginRight: 55
    }
  },
  link: {
    textDecoration: 'none'
  }
}))

const Login = () => {
  const classes = useStyles();
  const { appState, setAppState } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const snack = (msg, variant) => enqueueSnackbar(msg, { variant });

  const [formData, setFormData] = useState({ user: '', pw: '' });
  const [inputError, setInputError] = useState({ user: false, pw: false })
  const [submitState, setSubmitState] = useState(false)

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitState(true);
    loginHandler();
  }

  const loginHandler = async () => {
    if (formData.user && formData.pw) {
      try {
        const params = {
          cmd: "wialon.authenticate",
          type: "dynamic",
          params: {
            username: formData.user,
            password: formData.pw
          }
        };
        const options = {
          redirect: "follow",
          referrer: "no-referrer"
        }
        const response = await apiPost(process.env.REACT_APP_API_SERVER, params, options);
        if (response) {
          if (response.success) {
            return setAppState({ ...appState, auth: { loggedIn: true, user: formData.user, token: response.token } });
          } else if (!response.success) {
            setInputError({ user: true, pw: true });
            if ( response.msg === 'Access Denied: Dealer rights') {
              snack('You do not have dealer rights, please contact your Administrator.', 'error');
            } else if ( response.msg === 'Failed to fetch') {
              snack('The request failed: Unable to make request.', 'error');
            } else {
              snack('Your username and/or password do not match.', 'error');
            }
          }
        }
      } catch (err) {
        console.error('The request failed: Unable to make request.', 'error');
      }
    } else {
      setInputError({ user: !formData.user, pw: !formData.pw });
      snack('Error: Missing input. Please double check the fields.', 'error')
    }
    setSubmitState(false);
  }

  return (
    <Grid item className={classes.centerGrid} md={6} sm={8} xs={12} >
      <Paper className={classes.root}>

        <Typography variant="h5" gutterBottom style={{ textAlign: 'center', marginBottom: 15 }}>Sign In</Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            {!submitState
              ? <TextField
                type="text"
                id="username"
                className={classes.textField}
                name="user"
                label="Username"
                variant="outlined"
                error={inputError.user}
                onChange={e => {
                  if (inputError.user) setInputError({ ...inputError, user: !e.target.value });
                  setFormData({ ...formData, user: e.target.value })
                }}
                value={formData.user}
              />
              : <CustomSkeleton style={{width:'75%'}} />
            }
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            {!submitState
              ? <TextField
                type="password"
                id="password"
                className={classes.textField}
                label="Password"
                name="password"
                variant="outlined"
                error={inputError.pw}
                onChange={e => {
                  if (inputError.pw) setInputError({ ...inputError, pw: !e.target.value });
                  setFormData({ ...formData, pw: e.target.value })
                }}
                value={formData.pw}
              />
              : <CustomSkeleton style={{width:'75%'}} />
            }
          </Grid>
          <Grid item xs={12} className={classes.button_container}>
            <Button id="submitBtn" className={classes.button} type="submit" variant="contained" color="primary" size="large" disabled={submitState} onClick={handleSubmit} >Login</Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Login;