import localforage from "localforage";

export const apiPost = async (url, data, errorMessage, fetchOptions) => {
  console.log(`Making POST request to ${url}`);
  const storedState = await localforage.getItem('appState') || {auth: {token: '', user: ''}};
  const options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "same-origin",
    body: JSON.stringify({...data, token: storedState.auth.token, user: storedState.auth.user}),
    ...fetchOptions
  }

  try {
    const response = await fetch(url, options);
    return response.json();
  } catch (err) {
    return {
      success:false,
      msg: err.message || err
    }
  }

}