import React, { useState } from 'react';
import { Checkbox, Collapse, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ImportOptionSubCategory from './ImportOptionSubCategory';

const ImportOptionCategory = ({ title, template, options, setOptions }) => {
  const [open, setOpen] = useState(false);

  const handleCheckboxMain = e => {
    e.stopPropagation();
    if (e.target.checked) {
      setOptions({ list: template, all: true });
    } else {
      setOptions({ list: [], all: false });
    }
  }

  return (
    <>
      <ListItem dense button onClick={() => setOpen(!open)} >
        <ListItemIcon>
          <Checkbox color="primary" checked={options.all} onClick={handleCheckboxMain}/>
        </ListItemIcon>
        <ListItemText>{title}</ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open}>
        {template.map(item => <ImportOptionSubCategory
          key={item.n}
          value={item}
          total={template.length}
          options={options}
          setOptions={setOptions}
        />
        )}
      </Collapse>
    </>
  )
}

export default ImportOptionCategory;