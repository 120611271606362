import React, { useState, useEffect } from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';

import BarChartContainer from './BarChartContainer';

const SingleCustomer = ({ dataset, accountId, dates }) => {
  const [totalUnitsByCustomerInfo, setTotalUnitsByCustomerInfo] = useState([]);
  const [addsDeletionsByCustomer, setAddsDeletionsByCustomer] = useState([]);
  const [customerInformation, setCustomerInformation] = useState({})

  useEffect(() => {
    if (!dataset.dataA) return;

    const formatTotalUnitsByCustomer = () => {
      if (!dataset.dataA) return;
      let dataA = {},
        dataB = {},
        dataC = {},
        dataD = {}

      dataA["name"] = dates[0].label;
      if (dataset.dataA.unitsByCustomer[accountId]) {
        dataA["Total Units"] = dataset.dataA.unitsByCustomer[accountId].unitsTotal;
      }

      dataB["name"] = dates[1].label;
      if (dataset.dataB.unitsByCustomer[accountId]) {
        dataB["Total Units"] = dataset.dataB.unitsByCustomer[accountId].unitsTotal;
      }

      dataC["name"] = dates[2].label;
      if (dataset.dataC.unitsByCustomer[accountId]) {
        dataC["Total Units"] = dataset.dataC.unitsByCustomer[accountId].unitsTotal;
      }

      dataD["name"] = dates[3].label;
      if (dataset.dataD.unitsByCustomer[accountId]) {
        dataD["Total Units"] = dataset.dataD.unitsByCustomer[accountId].unitsTotal;
      }

      setCustomerInformation(prevState => {
        return { ...prevState, dataA: dataset.dataA.unitsByCustomer[accountId] }
      })
      setTotalUnitsByCustomerInfo([dataD, dataC, dataB, dataA]);
    }

    const formatAddsDeletions = () => {
      if (!dataset.delta) return;
      let dataContainer = [];
      let addDeleteObj = {
        "name": "Add/Delete",
        "Adds": 0,
        "Deletions": 0
      };
      let netObj = {
        "name": "Net Change: ",
        "Net": 0
      };

      const accountDelta = dataset.delta.unitsByCustomer[accountId];

      if (accountDelta) {
        addDeleteObj["Adds"] = accountDelta.unitsTotalAdded;
        addDeleteObj["Deletions"] = -accountDelta.unitsTotalDeleted;
        netObj["name"] += accountDelta.unitsTotalDelta;
        netObj["Net"] = accountDelta.unitsTotalDelta;
      }

      dataContainer.push(addDeleteObj, netObj);
      setCustomerInformation(prevState => {
        return { ...prevState, delta: accountDelta }
      })
      setAddsDeletionsByCustomer(dataContainer);
    }

    formatTotalUnitsByCustomer();
    formatAddsDeletions();

  }, [accountId, dataset, dates])

  return (
    <Grid container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
      <Grid item sm={12} md={6} style={{ padding: 5, width: '100%' }}>
        <Paper style={{ height: '100%' }} >
          <Grid style={{padding: 20}}>
            <Typography><strong>Total Units By Customer</strong></Typography>
            <Typography>
              {customerInformation.dataA && dataset.dataA.unitsByCustomer[accountId] ? `${customerInformation.dataA.accountName}: ${customerInformation.dataA.unitsTotal} Activated, ${customerInformation.dataA.unitsTotalDeactivated} Deactivated` : ''}
            </Typography>
          </Grid>
          <Grid style={{ height: 350 }}>
            <BarChartContainer data={totalUnitsByCustomerInfo} barDataKey={['Total Units']} />
          </Grid>
        </Paper>
      </Grid>
      <Grid item sm={12} md={6} style={{ padding: 5, width: '100%' }}>
        <Paper style={{ height: '100%' }} >
          <Grid style={{padding: 20}}>
            <Typography><strong>Adds and Deletions By Customer</strong></Typography>
            <Typography><strong>{dataset.dataA && dataset.dataA.unitsByCustomer[accountId] ? dataset.dataA.unitsByCustomer[accountId].accountName : 'No data'}</strong></Typography>
          </Grid>
          <Grid style={{ height: 350 }}>
            <BarChartContainer
              data={addsDeletionsByCustomer}
              legend={true}
              barDataKey={[{ name: 'Adds', color: "rgb(0,140,0)" }, { name: 'Deletions', color: "rgb(237,94,94)" }, { name: 'Net', color: "rgb(163,188,249)" }]}
              stacked={true}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SingleCustomer;