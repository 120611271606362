import React, {useState} from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { A } from 'hookrouter';

import addPerson from '../img/add-person.svg';
import addDevices from '../img/add-device.svg';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 0),
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  imageContainer: {
    margin: theme.spacing( 7, 0),
    justifyContent: 'center',
    flexDirection: 'row'
  },
  image: {
    margin: theme.spacing(0, 7),
    //saturate changes brightness, hue-rotate changes color
    filter: 'invert(0.5) sepia(1) saturate(65) hue-rotate(194deg)',
    "&:hover": {
      filter: 'invert(0.5) sepia(1) saturate(35) hue-rotate(194deg)'
    },
    "&:hover+.label": {
      color: '#1F8BFF'
    }
  },
  link: {
    textDecoration: 'none'
  },
  label: {
    margin: theme.spacing(2, 0),
    color: '#0077DC'
  }
  
}))

const Dashboard = () => {
  const classes = useStyles();
  const [imgLoaded, setImgLoaded] = useState({customer: false, devices: false})

  return (
    <Grid container className={classes.container}>
      <Typography variant="h3" align="center">SAAS Platform Manager</Typography>
      <Grid container className={classes.imageContainer} justify="center">
          <A id="newCustomerLink" href="/new-customer" className={classes.link}>
            <img className={classes.image} src={addPerson} alt="new customer" onLoad={() => setImgLoaded({...imgLoaded, customer: true})}/>
            { imgLoaded.customer && <Typography className={`label ${classes.label}`} align="center" margin="dense">New Customer</Typography>}
          </A>

          <A id="addDevicesLink" href="/add-devices" className={classes.link}>
            <img className={classes.image} src={addDevices} alt="add device" onLoad={() => setImgLoaded({...imgLoaded, devices: true})}/>
            { imgLoaded.devices && <Typography className={`label ${classes.label}`} align="center">Add Devices</Typography>}
          </A>
      </Grid>
    </Grid>
  )
}

export default Dashboard;
