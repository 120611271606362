import React, { useState, useEffect } from 'react';
import { List, makeStyles, Paper, ListSubheader } from '@material-ui/core';

import { apiPost } from '../../../lib/network';
import CustomSkeleton from '../../common/CustomSkeleton';
import ImportOptionCategory from './ImportOptionCategory';

const useStyles = makeStyles(theme => ({
  paper: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: 400
  },
  subheader: {
    padding: '10px 20px 10px', 
    borderBottom: '1px solid lightgray', 
    background: 'rgb(241, 241, 241)'
  }
}))

const ImportOption = ({ jobs, setJobs, notifications, setNotifications, reports, setReports }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [template, setTemplate] = useState({});


  useEffect(() => {
    const getDefaultTemplate = async () => {
      const response = await apiPost(process.env.REACT_APP_API_SERVER, {
        cmd: 'wialon.makeWlp',
        params: {}
      });
      if (response) {
        if (response.success) {
          const template = JSON.parse(response.result);
          setTemplate(template);
        }
      }
      
      setIsLoading(false);
    }

    getDefaultTemplate();
  }, []);

  return (
    <Paper className={classes.paper}>
      <ListSubheader variant="subtitle1" className={classes.subheader}>Import Options</ListSubheader>
      {!isLoading
        ? <List>
          {template.jobs
            ? <ImportOptionCategory title="Jobs" template={template.jobs} options={jobs} setOptions={setJobs} />
            : null
          }
          {template.notifications
            ? <ImportOptionCategory title="Notifications" template={template.notifications} options={notifications} setOptions={setNotifications} />
            : null
          }
          {template.reports
            ? <ImportOptionCategory title="Reports" template={template.reports} options={reports} setOptions={setReports} />
            : null
          }
        </List>
        : <CustomSkeleton style={{ border: 'none', margin: 10, height: 40 }} number={6} />
      }
    </Paper>
  )
}

export default ImportOption;