import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

const PieChartContainer = ({ data }) => {

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Legend layout="vertical" verticalAlign="middle" align="right" wrapperStyle={{ marginLeft: 10 }} />
        <Tooltip />
        <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" fill={"#8884d8"} >
          {
            data.map((entry, index) => <Cell key={index} fill={entry.color} />)
          }
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default PieChartContainer;