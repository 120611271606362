import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Grid, makeStyles, Typography, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import ExportFile from './ExportFile';
import ExportItem from './ExportItem';
import { ExportItemProvider } from '../../context/ExportItemContext';
import { apiPost } from '../../../lib/network';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  grid: {
    width: '100%',
    marginTop: 20
  },
  label: {
    marginBottom: 10
  },
  button: {
    marginTop: 35
  }
}))

const Export = () => {
  const classes = useStyles();
  const [exportState, setExportState] = useState('file');
  const [dealerListForFile, setDealerListForFile] = useState([]);
  const [dealerListForItem, setDealerListForItem] = useState([]);
  const [loadingStateForFile, setLoadingStateForFile] = useState({ account: true, unitGroup: false });
  const [loadingStateForItem, setLoadingStateForItem] = useState({ account: true, options: false });

  useEffect(() => {
    let isCancelled = false;
    const getDealers = async () => {
      const accountResponse = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: "wialon.getAccountsAll" });
      if (accountResponse.success && !isCancelled) {
        setDealerListForFile(accountResponse.result.items || []);
        if (!isCancelled) {
          setLoadingStateForFile(prevState => {
            return { account: false, unitGroup: prevState.unitGroup }
          });
        }

        const allAccountId = accountResponse.result.items.map(item => item.crt);
        const unitGroupsResponse = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: "wialon.getUnitgroupsInAccountByBatch", params: { accounts: allAccountId } });
        if (unitGroupsResponse.success) {
          for (let account of accountResponse.result.items) {
            for (const unitGroup of unitGroupsResponse.result) {
              if (account.crt + "" === unitGroup.searchSpec.propValueMask) {
                account.unit = unitGroup.items;
              }
            }
          }
        } else {
          accountResponse.result.items.forEach(item => item.unit = [])
        }

        setDealerListForItem(JSON.parse(JSON.stringify(accountResponse.result.items)));

        if (!isCancelled) {
          setLoadingStateForItem(prevState => {
            return { account: false, option: prevState.option }
          });
        }
        
      } else {
        console.error(accountResponse);
      }
    }
    getDealers();

    return () => { isCancelled = true }
  }, [])

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={8} md={6}>
        <Typography variant="h4" align="center">Export to WLP</Typography>
        <Typography style={{marginTop: 10}}>Export notifications, jobs, and reports template for new MiFleet accounts.</Typography>
        <Grid item style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}>
          <Typography display="inline">Export to:</Typography>
          <ButtonGroup size="small" style={{ paddingLeft: 10 }}>
            <Button variant={exportState === 'file' ? 'contained' : 'outlined'} color="primary" style={{ width: 70 }} onClick={() => setExportState('file')}>File</Button>
            <Button variant={exportState === 'item' ? 'contained' : 'outlined'} color="primary" style={{ width: 70 }} onClick={() => setExportState('item')}>Item</Button>
          </ButtonGroup>
          <Tooltip
            disableFocusListener
            disableTouchListener
            placement="right"
            style={{ paddingLeft: 10 }}
            title='Export to WLP allows you to export data from an object to a WLP file or straight to another object.'
          >
            <HelpOutlineIcon fontSize="small" />
          </Tooltip>
        </Grid>
        {exportState === 'file' && <ExportFile accountList={dealerListForFile} isLoading={loadingStateForFile} setIsLoading={setLoadingStateForFile}/>}
        {exportState === 'item' && <ExportItemProvider><ExportItem accountList={dealerListForItem} isLoading={loadingStateForItem} setIsLoading={setLoadingStateForItem} /></ExportItemProvider>}
      </Grid>
    </Grid>
  )
}

export default Export;