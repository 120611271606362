import React, {useState, useEffect} from 'react';
import { Checkbox, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(6),
    background: 'rgba(0, 0, 0, 0.04)'
  }
}))

const ImportDestinationUnit = ({value, handleCheckbox, accounts}) => {
  const classes = useStyles();
  const [state, setState] = useState(false);

  useEffect(() => {
    if ( !accounts.length ) setState(false);
    for ( let item of accounts ){
      for ( let unit of item.unit ) {
        if ( unit.id === value.id){
          return setState(true);
        }
      }
    }
    setState(false);
  }, [accounts, value.id]);

  return (
    <ListItem key={value.id} dense className={classes.nested}>
      <ListItemIcon>
        <Checkbox color="primary" checked={state} onClick={e => handleCheckbox(e, value)} />
      </ListItemIcon>
      <ListItemText>{value.nm}</ListItemText>
    </ListItem>
  )
}

export default ImportDestinationUnit;