import React, { useState, useEffect } from 'react';
import { Checkbox, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(6),
  }
}))

const ExportItemSubCategory = ({ value, category, option, setOption, parent, setMainCheckState }) => {
  const classes = useStyles();
  const [state, setState] = useState(false);

  useEffect(() => {
    const copiedValue = JSON.parse(JSON.stringify(value));
    for (let item of option) {
      if (item.category === category) {
        for (let val of item.list) {
          if (val.n === copiedValue.n) {
            setState(true);
            return;
          }
        }
      }
    }
    setState(false);
  })

  const subCategoryClickHandler = e => {
    let updatedList = [];
    let copiedValue = JSON.parse(JSON.stringify(value));
    let added = false;
    if (!option.length) {
      let container = { category: category, list: [copiedValue] };
      setState(true);
      setMainCheckState(true);
      return setOption([container]);
    }
    for (let item of option) {
      if (item.category === category) {
        if (e.target.checked) {
          item.list.push(copiedValue);
        } else {
          item.list = item.list.filter(sub => sub.n !== copiedValue.n);
          setState(false);
        }
        added = true;
      }
      updatedList.push(item);
    }

    if (!added) {
      let container = { category: category, list: [copiedValue] };
      setState(true);
      setMainCheckState(true);
      updatedList.push(container);
    }

    updatedList = updatedList.filter(item => {
      if (item.list.length) {
        return true;
      } else {
        setMainCheckState(false);
        return false;
      }
    });
    setOption(updatedList);
  }

  return (
    <ListItem dense className={classes.nested}>
      <ListItemIcon>
        <Checkbox color="primary" checked={state} onClick={subCategoryClickHandler} />
      </ListItemIcon>
      <ListItemText>{value.n}</ListItemText>
    </ListItem>
  )
}

export default ExportItemSubCategory;