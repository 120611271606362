import React, { useContext } from 'react';
import { AppBar, makeStyles, Toolbar, Typography, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { A } from 'hookrouter';

import { AppContext } from '../AppContext';

const useStyles = makeStyles(theme => ({
  navTitle: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText
  }
}))

const Header = ({ state, setState }) => {
  const classes = useStyles();
  const { appState } = useContext(AppContext);

  const renderBreadcrumb = () => {
    let title = "SaaS Uploader";
    let pathname = window.location.pathname;
    if (appState.auth.loggedIn) {
      if (pathname === '/') {
        return title += ' / Dashboard';
      }
      if (pathname === '/new-customer') {
        return title += ' / New Customer';
      }
      if (pathname === '/add-devices') {
        return title += ' / Add Devices';
      }
      if (pathname === '/export-wlp') {
        return title += ' / Export WLP';
      }
      if (pathname === '/import-wlp') {
        return title += ' / Import WLP';
      }
      if (pathname === '/reports') {
        return title += ' / Reports';
      }
    }
    return title;

  }

  return (
    <AppBar position="static">
      <Toolbar>
        {appState.auth.loggedIn
          ? <IconButton edge="start" className={classes.menuButton} color="inherit" onClick={() => setState(!state)}>
            <MenuIcon />
          </IconButton>
          : null
        }
        <A className={classes.navTitle} href="/">
          <Typography variant="h6" className={classes.title}>{renderBreadcrumb()}</Typography>
        </A>
      </Toolbar>
    </AppBar>
  )
}

export default Header;