import React, { useState } from 'react';
import localforage from "localforage";

export const AppContext = React.createContext({});

export const AppProvider = (props) => {
  const [state, setState] = useState({
    auth: {
      loggedIn: false,
      user: '',
      token: ''
    }
  });
  const [loadingDOM, setLoadingDOM ] = useState(true);

  const setAppState = async state => {
    if ( navigator.userAgent === "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0 Safari/537.36 Ghost Inspector") {
      sessionStorage.setItem('ghost', true);
    }
    
    try {
      await localforage.setItem('appState', state);
    } catch (err) {
      console.error(err)
    }
    setState(state);
  }

  return (
    <AppContext.Provider value={{ appState: state, setAppState, loadingDOM, setLoadingDOM }}>
      {props.children}
    </AppContext.Provider>
  )
}

