import React, { useState, useEffect } from 'react';
import { FormControl, Grid, makeStyles, MenuItem, Typography, Select, TextField, InputLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';

import { apiPost } from '../../lib/network';
import { parseUnitsData, getFirstOfMonth, getPreviousMonth } from './helper';
import TotalChartContainer from './TotalChartContainer';
import SingleCustomer from './SingleCustomer';
import Loader from '../../img/triangle-loader.svg';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 0),
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'

  },
  button: {
    marginTop: 35
  },
  loader: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  [theme.breakpoints.up('sm')]: {
    buttonGrid: {
      width: '30%',
      margin: 0
    }
  },
  [theme.breakpoints.down('sm')]: {
    buttonGrid: {
      width: '100%',
      margin: '5px 0'
    }
  }
}))

const Reports = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const snack = (msg, variant) => enqueueSnackbar(msg, { variant });

  const [initialLoadState, setInitialLoadState] = useState(true);
  const [dataLoadState, setDataLoadState] = useState(false);
  const [dataset, setDataset] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [selectedDate, setSelectedDate] = useState('today');
  const [selectedCustomer, setSelectedCustomer] = useState('15256018');
  const [dates, setDates] = useState([]);

  //Get list of available months only on first run
  useEffect(() => {
    const today = new Date()
    const epoch = 1546300800000 // 1 December 2018. The first data was collected on 1 December 2018, and beginning on 1 January 2019 we can compare the data and generate reports.
    const dateList = [];

    const getPreviousMonths = (dateInput, i = 0) => {
      if ((dateInput).getTime() <= epoch) return;

      const previousMonth = getPreviousMonth(dateInput);
      const result = {};
      result.date = getFirstOfMonth(dateInput).date;
      result.label = previousMonth.label;
      dateList.push(result);
      return getPreviousMonths(previousMonth.date, ++i);
    };

    getPreviousMonths(today);

    setAvailableMonths(dateList);

  }, [])

  useEffect(() => {
    if (!initialLoadState) {
      setDataLoadState(true);
    }

    const makeReport = async (dateInput) => {
      let unitsDataA, // Newer data
        unitsDataB, // Older data
        unitsDataC, // 3m Older data
        unitsDataD, // 4m Older data
        dateA = {},
        dateB = {},
        dateC = {},
        dateD = {},
        response,
        usc;

      const IntlOpts = {
        month: "long",
        year: "numeric"
      };

      if (dateInput && dateInput === "today") {
        const today = new Date();
        dateA.date = today; // Today
        dateA.label = new Intl.DateTimeFormat("en-US", IntlOpts).format(dateA.date);
        dateB = getFirstOfMonth(dateA.date); // First of month
        dateB.label = getPreviousMonth(dateB.date).label; // Data from the first of the month is actually last month's data, so label it accordingly.
        dateC = getPreviousMonth(dateB.date); // First of month
        dateC.label = getPreviousMonth(dateC.date).label; // Data from the first of the month is actually last month's data, so label it accordingly.
        dateD = getPreviousMonth(dateC.date); // First of month
        dateD.label = getPreviousMonth(dateD.date).label; // Data from the first of the month is actually last month's data, so label it accordingly.
        setDates([dateA, dateB, dateC, dateD]);

        // Pull today's data
        response = await apiPost(process.env.REACT_APP_API_SERVER, {
          cmd: "spm.wialon.getReportsData",
          params: {
            monthlyOrDaily: "daily",
            pull_date: dateA.date
          }
        });
        unitsDataA = response.result;

        // If there's no data from today, try yesterday instead.
        if (!unitsDataA || unitsDataA.length === 0) {
          response = await apiPost(process.env.REACT_APP_API_SERVER, {
            cmd: "spm.wialon.getReportsData",
            params: {
              monthlyOrDaily: "daily",
              pull_date: new Date(Date.now() - 86400000)
            }
          });
          unitsDataA = response.result;
        }

        // Get current USC units
        try {
          response = await apiPost(process.env.REACT_APP_API_SERVER, {
            cmd: "wialon.searchItems",
            params: {
              spec: {
                itemsType: "avl_unit",
                propName: "sys_unit_groups",
                propValueMask: "400027175",
                sortType: "sys_name",
                propType: "list"
              },
              force: 1,
              from: 0,
              to: 0,
              flags: 261,
            },
          })
          usc = response.result.items;
        } catch (err) {
          console.error(err)
          snack("Couldn't fetch USC unit group.", 'error')
        }

        // Pull data from last month
        response = await apiPost(process.env.REACT_APP_API_SERVER, {
          cmd: "spm.wialon.getReportsData",
          params: {
            monthlyOrDaily: "monthly",
            pull_date: dateB.date
          }
        });
        unitsDataB = response.result;

        // Pull data from 3 month
        response = await apiPost(process.env.REACT_APP_API_SERVER, {
          cmd: "spm.wialon.getReportsData",
          params: {
            monthlyOrDaily: "monthly",
            pull_date: dateC.date
          }
        });
        unitsDataC = response.result;

        // Pull data from 4 month
        response = await apiPost(process.env.REACT_APP_API_SERVER, {
          cmd: "spm.wialon.getReportsData",
          params: {
            monthlyOrDaily: "monthly",
            pull_date: dateD.date
          }
        });
        unitsDataD = response.result;


        // Pull data from given date
      } else if (dateInput) {
        if (typeof dateInput !== "object") dateInput = new Date(dateInput);
        dateA = getFirstOfMonth(dateInput);
        dateA.label = getPreviousMonth(dateA.date).label; // Data from the first of the month is actually last month's data, so label it accordingly.
        dateB = getPreviousMonth(dateA.date);
        dateB.label = getPreviousMonth(dateB.date).label; // Data from the first of the month is actually last month's data, so label it accordingly.
        dateC = getPreviousMonth(dateB.date); // First of month
        dateC.label = getPreviousMonth(dateC.date).label; // Data from the first of the month is actually last month's data, so label it accordingly.
        dateD = getPreviousMonth(dateC.date); // First of month
        dateD.label = getPreviousMonth(dateD.date).label; // Data from the first of the month is actually last month's data, so label it accordingly.
        setDates([dateA, dateB, dateC, dateD]);

        // Pull last month's data
        response = await apiPost(process.env.REACT_APP_API_SERVER, {
          cmd: "spm.wialon.getReportsData",
          params: {
            monthlyOrDaily: "monthly",
            pull_date: dateA.date
          }
        });
        unitsDataA = response.result;

        // Pull data from month before last month
        response = await apiPost(process.env.REACT_APP_API_SERVER, {
          cmd: "spm.wialon.getReportsData",
          params: {
            monthlyOrDaily: "monthly",
            pull_date: dateB.date
          }
        });
        unitsDataB = response.result;

        // Pull data from 3month before last month
        response = await apiPost(process.env.REACT_APP_API_SERVER, {
          cmd: "spm.wialon.getReportsData",
          params: {
            monthlyOrDaily: "monthly",
            pull_date: dateC.date
          }
        });
        unitsDataC = response.result;

        // Pull data from 4month before last month
        response = await apiPost(process.env.REACT_APP_API_SERVER, {
          cmd: "spm.wialon.getReportsData",
          params: {
            monthlyOrDaily: "monthly",
            pull_date: dateD.date
          }
        });
        unitsDataD = response.result;
      } else throw new Error("Missing parameter `dateInput` from function SPM.reports.makeReport.");

      let parsed = {};
      let customerListArr = [];
      try {
        parsed = parseUnitsData(unitsDataA, unitsDataB, unitsDataC, unitsDataD);
        for (const key in parsed.dataA.unitsByCustomer) {
          customerListArr.push(parsed.dataA.unitsByCustomer[key]);
        }
        parsed.usc = usc || [];
      } catch (err) {
        console.error(err);
        snack('Unable to fetch data', 'error')
      }

      setDataset(parsed);
      setCustomerList(customerListArr);
      setInitialLoadState(false);
      setDataLoadState(false);

    }

    makeReport(selectedDate);

  }, [selectedDate]);

  const downloadHandler = e => {
    if (!dataset.dataA) return;
    const value = e.target.value;
    let date = selectedDate;
    if (date && date === 'today') {
      date = new Date();
    }
    const filenameDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    const prepBillingReportData = () => {
      const billingReportData = [];

      const customerIds = Object.keys(dataset.dataA.unitsByCustomer);
      billingReportData.push(["Account ID", "Customer name", "Net change", "Total units", "Total deactivated"]);
      let totalChange = 0;
      let totalUnits = 0;
      for (const customerId of customerIds) {
        const customer = dataset.dataA.unitsByCustomer[customerId];
        const deltaCustomer = dataset.delta.unitsByCustomer[customerId];
        let arr;
        if (customerId) {
          arr = [
            customerId,
            customer.accountName || ""
          ];
          if (deltaCustomer && (deltaCustomer.unitsTotalDelta || deltaCustomer.unitsTotalDelta === 0)) {
            arr.push(deltaCustomer.unitsTotalDelta);
            totalChange += deltaCustomer.unitsTotalDelta;
          } else {
            arr.push(0)
          }
          arr.push(customer.unitsTotal);
          arr.push(customer.unitsTotalDeactivated);
          totalUnits += customer.unitsTotal;
        }
        billingReportData.push(arr);
      }
      billingReportData.splice(1, 0, ["", "All customers", totalChange, totalUnits, dataset.dataA.unitsTotalDeactivated]);

      downloadXlsx(billingReportData, 'billing-report')
    };

    // Delta report data
    const prepDeltaReportData = () => {
      const deltaReportData = [];

      const customerIds = Object.keys(dataset.delta.unitsByCustomer);
      deltaReportData.push([
        "Added or deleted",
        "ESN (UID)",
        "UID2",
        "Device type ID",
        "Device type",
        "Our customer account ID",
        "Our customer account name",
        "Account ID",
        "Account name",
        "Creator ID",
        "Creator name",
        "Active",
        "Creation date",
        "Custom fields",
        "Deactivation date",
        "ID",
        "Last message date",
        "Phone",
        "Data retrieval date",
        "Unit ID",
        "Unit name"
      ]);

      for (const customerId of customerIds) {
        const customer = dataset.delta.unitsByCustomer[customerId];
        if (customerId && customer.accountName) {
          const unitIdsAdded = Object.keys(customer.unitsAdded);
          const unitIdsDeleted = Object.keys(customer.unitsDeleted);

          for (const unitId of unitIdsAdded) {
            const unit = customer.unitsAdded[unitId];
            deltaReportData.push([
              "Added",
              unit.uid,
              unit.uid2,
              unit.device_type_id,
              unit.device_type_name,
              unit.ourcustomer_account_id,
              unit.ourcustomer_account_name,
              unit.account_id,
              unit.account_name,
              unit.creator_id,
              unit.creator_name,
              unit.active,
              unit.creation_date,
              unit.custom_fields,
              unit.deactivation_date,
              unit.id,
              unit.last_message_date,
              unit.phone,
              unit.pull_date,
              unit.unit_id,
              unit.unit_name
            ]);
          }

          for (const unitId of unitIdsDeleted) {
            const unit = customer.unitsDeleted[unitId];
            deltaReportData.push([
              "Deleted",
              unit.uid,
              unit.uid2,
              unit.device_type_id,
              unit.device_type_name,
              unit.ourcustomer_account_id,
              unit.ourcustomer_account_name,
              unit.account_id,
              unit.account_name,
              unit.creator_id,
              unit.creator_name,
              unit.active,
              unit.creation_date,
              unit.custom_fields,
              unit.deactivation_date,
              unit.id,
              unit.last_message_date,
              unit.phone,
              unit.pull_date,
              unit.unit_id,
              unit.unit_name
            ]);
          }
        }
      }
      downloadXlsx(deltaReportData, 'all-adds-and-deletions')
    };

    // Customer all units
    const prepCustomerAllUnitsData = (customerId) => {
      const customerAllUnits = [];

      customerAllUnits.push([
        "ESN (UID)",
        "UID2",
        "Device type ID",
        "Device type",
        "Our customer account ID",
        "Our customer account name",
        "Account ID",
        "Account name",
        "Creator ID",
        "Creator name",
        "Active",
        "Creation date",
        "Custom fields",
        "Deactivation date",
        "ID",
        "Last message date",
        "Phone",
        "Data retrieval date",
        "Unit ID",
        "Unit name"
      ]);
      const customer = dataset.dataA.unitsByCustomer[customerId];
      if (customerId && customer.accountName) {
        const unitIds = Object.keys(customer.units);

        for (const unitId of unitIds) {
          const unit = customer.units[unitId];
          customerAllUnits.push([
            unit.uid,
            unit.uid2,
            unit.device_type_id,
            unit.device_type_name,
            unit.ourcustomer_account_id,
            unit.ourcustomer_account_name,
            unit.account_id,
            unit.account_name,
            unit.creator_id,
            unit.creator_name,
            unit.active,
            unit.creation_date,
            unit.custom_fields,
            unit.deactivation_date,
            unit.id,
            unit.last_message_date,
            unit.phone,
            unit.pull_date,
            unit.unit_id,
            unit.unit_name
          ]);
        }
      }
      downloadXlsx(customerAllUnits, customer.accountName);
    };

    // Customer delta
    const prepCustomerDeltaData = (customerId) => {
      const customerDelta = [];

      customerDelta.push([
        "Added or deleted",
        "ESN (UID)",
        "UID2",
        "Device type ID",
        "Device type",
        "Our customer account ID",
        "Our customer account name",
        "Account ID",
        "Account name",
        "Creator ID",
        "Creator name",
        "Active",
        "Creation date",
        "Custom fields",
        "Deactivation date",
        "ID",
        "Last message date",
        "Phone",
        "Data retrieval date",
        "Unit ID",
        "Unit name"
      ]);
      const customer = dataset.delta.unitsByCustomer[customerId];
      if (customerId && customer.accountName) {
        const unitIdsAdded = Object.keys(customer.unitsAdded);
        const unitIdsDeleted = Object.keys(customer.unitsDeleted);

        for (const unitId of unitIdsAdded) {
          const unit = customer.unitsAdded[unitId];
          customerDelta.push([
            "Added",
            unit.uid,
            unit.uid2,
            unit.device_type_id,
            unit.device_type_name,
            unit.ourcustomer_account_id,
            unit.ourcustomer_account_name,
            unit.account_id,
            unit.account_name,
            unit.creator_id,
            unit.creator_name,
            unit.active,
            unit.creation_date,
            unit.custom_fields,
            unit.deactivation_date,
            unit.id,
            unit.last_message_date,
            unit.phone,
            unit.pull_date,
            unit.unit_id,
            unit.unit_name
          ]);
        }

        for (const unitId of unitIdsDeleted) {
          const unit = customer.unitsDeleted[unitId];
          customerDelta.push([
            "Deleted",
            unit.uid,
            unit.uid2,
            unit.device_type_id,
            unit.device_type_name,
            unit.ourcustomer_account_id,
            unit.ourcustomer_account_name,
            unit.account_id,
            unit.account_name,
            unit.creator_id,
            unit.creator_name,
            unit.active,
            unit.creation_date,
            unit.custom_fields,
            unit.deactivation_date,
            unit.id,
            unit.last_message_date,
            unit.phone,
            unit.pull_date,
            unit.unit_id,
            unit.unit_name
          ]);
        }
      }
      downloadXlsx(customerDelta, customer.accountName);
    };

    // USC
    const prepUSC = () => {
      const result = [['ESN']]

      for (let i = 1, l = dataset.usc.length; i < l; i++) {
        const unit = dataset.usc[i]
        const esn = unit.uid
        result.push([esn])
      }

      downloadXlsx(result, 'us-cellular');
    }

    const downloadXlsx = (data, filename = "data", worksheetName = "MiFleet data") => {
      filename = filename.toLowerCase().split(" ").join("-");
      filename = `mifleet-${filename}-${filenameDate}.xlsx`;
      worksheetName = `${worksheetName} ${filenameDate}`;

      const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);

      /* add worksheet to workbook */
      XLSX.utils.book_append_sheet(wb, ws, worksheetName);

      /* write workbook */
      XLSX.writeFile(wb, filename);
    };

    // JSON download button
    const prepJSON = () => {
      const blob = new Blob([JSON.stringify(dataset)], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `mifleet-${filenameDate}.json`, { autoBOM: false });
    }

    switch (value) {
      case "billing":
        return prepBillingReportData();
      case "adds-deletions":
        return prepDeltaReportData();
      case "usc":
        return prepUSC();
      case "account-all":
        return prepCustomerAllUnitsData(selectedCustomer);
      case "account-add-deletions":
        return prepCustomerDeltaData(selectedCustomer);
      case "all-json":
        return prepJSON();
      default:
        return snack('Download Option Not Found', 'error')
    }

  }


  const setAutocompleteDefaultValue = () => {
    let defaultValueIndex;
    for (let index in customerList) {
      if (customerList[index].accountId === selectedCustomer && customerList[index].accountName === "DealerBluegrass") {
        defaultValueIndex = index;
        break;
      }
    }
    return defaultValueIndex;
  }

  const renderDownloadOptionAccount = () => {
    const renderItem = [];
    if (dataset.dataA && dataset.dataA.unitsByCustomer[selectedCustomer]) {
      renderItem.push(
        <MenuItem key="account-all" value="account-all">{dataset.dataA.unitsByCustomer[selectedCustomer].accountName} all units</MenuItem>,
        <MenuItem key="account-add-deletions" value="account-add-deletions">{dataset.dataA.unitsByCustomer[selectedCustomer].accountName} adds and deletions</MenuItem>
      )
    }
    return renderItem;
  }

  return (
    <Grid container className={classes.container} >
      {!initialLoadState
        ? <Grid item style={{ width: '100%' }}>
          <Typography variant="h3" align="center">MiFleet</Typography>
          <Grid item style={{ width: '100%' }}>
            <Typography align="center">
              {dataset.dataA ? dataset.dataA.unitsTotal : '0'} billable units: {dataset.dataA ? dataset.dataA.unitsTotalBillable : '0'} total, ({dataset.dataA ? dataset.dataA.unitsPercentNonbillable : '0'}%)
              </Typography>
            <Typography align="center">
              Deactivated: {dataset.dataA ? `${dataset.dataA.unitsTotalDeactivated}` : '0'} {dataset.dataA && selectedDate === 'today' ? `, Current USC Units: ${dataset.usc.length}` : ''}
            </Typography>
          </Grid>

          <Grid container style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Grid className={classes.buttonGrid}>
              <Select variant="outlined" value={selectedDate} style={{ width: '100%' }} onChange={e => setSelectedDate(e.target.value)}>
                <MenuItem value="today">This month</MenuItem>
                {availableMonths.map(date => <MenuItem key={date.label} value={date.date}>{date.label}</MenuItem>)}
              </Select>
            </Grid>
            <Grid className={classes.buttonGrid}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="input-placeholder">Download Options</InputLabel>
                <Select value="" labelId="input-placeholder" label="Download Options" onChange={downloadHandler}>
                  <MenuItem value="billing">Billing Report</MenuItem>
                  <MenuItem value="adds-deletions">All adds and deletions</MenuItem>
                  <MenuItem value="usc">US Cellular Units</MenuItem>
                  {renderDownloadOptionAccount()}
                  <MenuItem value="all-json">All data as JSON</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <TotalChartContainer dataset={dataset} date={selectedDate} dates={dates} />

          <Grid container style={{ width: '100%', justifyContent: 'center', marginTop: 20 }}>
            <Grid item style={{ width: '100%' }}>
              <Typography variant="h4">Single Customer</Typography>
            </Grid>
            <Grid item sm={12} md={4} style={{ marginTop: 10, width: '100%' }} >
              <Autocomplete
                options={customerList}
                getOptionLabel={options => options.accountName}
                disableClearable
                defaultValue={() => {
                  let index = setAutocompleteDefaultValue();
                  return customerList[index];
                }}
                onChange={(e, v) => setSelectedCustomer(v.accountId)}
                renderOption={option => <span>{option.accountName}</span>}
                renderInput={params => <TextField
                  {...params}
                  variant="outlined"
                />}
              />
            </Grid>

            <SingleCustomer dataset={dataset} accountId={selectedCustomer} dates={dates} />
          </Grid>

          {dataLoadState && <img src={Loader} alt="" className={classes.loader} />}
        </Grid>
        : <img src={Loader} alt="" className={classes.loader} />
      }
    </Grid >
  )
}

export default Reports;
