import React from 'react';
import { render } from 'react-dom';
import { SnackbarProvider } from 'notistack';

import App from './components/App';
import { AppProvider } from './AppContext';

import './index.css';

render(
  <AppProvider>
    <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
      <App />
    </SnackbarProvider>
  </AppProvider>,
  document.getElementById('root'));