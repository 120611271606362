import React, { useState } from 'react';

export const ExportItemContext = React.createContext({});

export const ExportItemProvider = (props) => {

  const [accountList, setAccountList] = useState([]);
  const [importAccountList, setImportAccountList] = useState({});

  return (
    <ExportItemContext.Provider value={{accountList, setAccountList,importAccountList, setImportAccountList}}>
      {props.children}
    </ExportItemContext.Provider>
  )
}

