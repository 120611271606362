import React, { useState, useEffect } from 'react';
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';

import BarChartContainer from './BarChartContainer';
import PieChartContainer from './PieChartContainer';
import { getFirstOfMonth, getPreviousMonth } from './helper';

let colorList = [
  "rgb(0,170,255)", //niceBlue 
  "rgb(240,195,50)", //mustardYellow
  "rgb(237,94,94)", //red
  "rgb(60,200,200)", //aquamarine
  "rgb(255,103,197)", //bubblegumPink
  "rgb(103,135,228)", //paleishBlue
  "rgb(255,145,35)", //pumpkinOrange
  "rgb(70,160,95)", //green
  "rgb(255,80,150)", //magenta
  "rgb(163,188,249)", //reallyLightBlue
  "rgb(153,92,214)", //purple
  "rgb(255,95,0)", //veryRedOrange
  "rgb(10,70,180)", //navyBlue
  "rgb(235,215,0)", //superYellow
  "rgb(255,20,0)", //superRed
  "rgb(0,140,0)", //forestGreen
  "rgb(195,140,245)", //lavender
  "rgb(255,123,38)" // orange
];

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.up('sm')]: {
    listContainer: {
      minHeight: 200
    },
    paddingLeft: {
      padding: '5px 0 5px 5px'
    },
    paddingRight: {
      padding: '5px 5px 5px 0'
    }
  },
  [theme.breakpoints.down('sm')]: {
    listContainer: {
      minHeight: 200
    },
    paddingLeft: {
      padding: '5px 0'
    },
    paddingRight: {
      padding: '5px 0'
    }
  },
  [theme.breakpoints.down('md')]: {
    accountList: {
      padding: 0
    }
  }
}))

const TotalChartContainer = ({ dataset, date, dates }) => {
  const classes = useStyles();
  const [totalUnitChartInfo, setTotalUnitChartInfo] = useState([]);
  const [totalAddsDeletions, setTotalAddsDeletions] = useState([]);
  const [totalTopCustomers, setTotalTopCustomers] = useState([]);

  useEffect(() => {
    const formatTotalUnitsData = () => {
      if (!dataset.dataA) return;
      let dateA = {};
      let dateB = {};

      const IntlOpts = {
        month: "long",
        year: "numeric"
      };

      if (date && date === 'today') {
        date = new Date();
        dateA["name"] = new Intl.DateTimeFormat("en-US", IntlOpts).format(date);
        dateA["Total Units"] = dataset.dataA.unitsTotalBillable

        let previousMonth = getFirstOfMonth(date); // First of month
        dateB["name"] = getPreviousMonth(previousMonth.date).label; // Data from the first of the month is actually last month's data, so label it accordingly.
        dateB["Total Units"] = dataset.dataB.unitsTotalBillable
      } else if (date) {
        let firstOfMonth = getFirstOfMonth(date);
        dateA["name"] = getPreviousMonth(firstOfMonth.date).label; // Data from the first of the month is actually last month's data, so label it accordingly.
        dateA["Total Units"] = dataset.dataA.unitsTotalBillable

        let previousMonth = getPreviousMonth(firstOfMonth.date);
        dateB["name"] = getPreviousMonth(previousMonth.date).label; // Data from the first of the month is actually last month's data, so label it accordingly.
        dateB["Total Units"] = dataset.dataB.unitsTotalBillable
      }

      setTotalUnitChartInfo([dateB, dateA]);
    };

    const formatTotalAddsDeletions = () => {
      if (!dataset.delta) return;
      let dataContainer = [];
      let addDeleteObj = {
        "name": "Add/Delete",
        "Adds": 0,
        "Deletions": 0
      };
      let netObj = {
        "name": "Net Change: ",
        "Net": 0
      };
      addDeleteObj["Adds"] = dataset.delta.unitsTotalBillableAdded;
      addDeleteObj["Deletions"] = -dataset.delta.unitsTotalBillableDeleted;
      netObj["name"] += dataset.delta.unitsTotalBillableDelta;
      netObj["Net"] = dataset.delta.unitsTotalBillableDelta;

      dataContainer.push(addDeleteObj, netObj);
      setTotalAddsDeletions(dataContainer);
    };

    const formatTopCustomer = () => {
      if (!dataset.dataA) return;
      let count = 0;
      let topCustomersUnitCount = 0;
      let dataContainer = [];
      for (const data of dataset.dataA.topCustomers) {
        let dataObj = {};
        dataObj["name"] = data.accountName;
        dataObj["value"] = data.unitsTotal;
        dataObj["color"] = colorList[count];
        dataContainer.push(dataObj);
        topCustomersUnitCount += data.unitsTotal;
        count++;
      }
      let otherCustomerObj = {}
      otherCustomerObj["name"] = "Other Customers";
      otherCustomerObj["value"] = dataset.dataA.unitsTotalBillable - topCustomersUnitCount;
      otherCustomerObj["color"] = 'lightgray';
      dataContainer.push(otherCustomerObj);

      setTotalTopCustomers(dataContainer);
    }

    formatTotalUnitsData();
    formatTotalAddsDeletions();
    formatTopCustomer();

  }, [dataset])

  const renderList = (accounts) => {
    let accountsArr = [];
    for (let key in accounts) {
      accountsArr.push(<li key={key}>{accounts[key].accountName}</li>);
    }

    if (!accountsArr.length) {
      accountsArr.push(<li key="none" style={{ listStyle: 'none' }}>None</li>);
    }

    return accountsArr;
  }

  return (
    <Grid container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
      <Grid item sm={12} md={6} className={classes.paddingRight} style={{ width: '100%' }}>
        <Paper style={{ height: "100%" }} >
          <Grid style={{padding: 20}}>
            <Typography><strong>Total Units</strong></Typography>
            <Typography>
              {dataset.dataA ? dataset.dataA.unitsTotalBillable : 0} activated, {dataset.dataA ? dataset.dataA.unitsTotalDeactivated : 0} deactived
            </Typography>
          </Grid>
          <Grid style={{ height: 350 }}>
            <BarChartContainer data={totalUnitChartInfo.length ? totalUnitChartInfo : []} barDataKey={['Total Units']} />
          </Grid>

        </Paper>
      </Grid>
      <Grid item sm={12} md={6} className={classes.paddingLeft} style={{ width: '100%' }}>
        <Paper style={{ height: '100%' }} >
          <Grid style={{padding: 20}}>
            <Typography>
              <strong>Total Adds and Deletions {dates.length ? `For ${dates[0].label}` : ''}</strong>
            </Typography>
          </Grid>
          <Grid style={{ height: 350 }}>
            <BarChartContainer
              data={totalAddsDeletions}
              legend={true}
              barDataKey={[{ name: 'Adds', color: "rgb(0,140,0)" }, { name: 'Deletions', color: "rgb(237,94,94)" }, { name: 'Net', color: "rgb(163,188,249)" }]}
              stacked={true}
            />
          </Grid>
        </Paper>
      </Grid>
      <Grid item sm={12} md={6} className={classes.paddingRight} style={{ width: '100%' }}>
        <Paper style={{ height: '100%' }} >
          <Grid style={{padding: 20}}>
            <Typography><strong>Top Customers</strong></Typography>
          </Grid>
          <Grid style={{ height: 350 }}>
            <PieChartContainer data={totalTopCustomers} />
          </Grid>
        </Paper>
      </Grid>
      <Grid item sm={12} md={6} className={classes.paddingLeft} style={{ width: '100%' }}>
        <Paper className={classes.listContainer} style={{ height: '100%' }} >
          <Grid container style={{padding: 20}}>
            <Grid item sm={12} md={6}>
              <Typography><strong>Accounts Added</strong></Typography>
              <ul className={classes.accountList}>
                {dataset.delta && renderList(dataset.delta.accountsAdded)}
              </ul>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography><strong>Accounts Deleted</strong></Typography>
              <ul className={classes.accountList}>
                {dataset.delta && renderList(dataset.delta.accountsDeleted)}
              </ul>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default TotalChartContainer;
