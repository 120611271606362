import React, { useContext } from 'react';
import { Grid, List, makeStyles, Paper, InputLabel } from '@material-ui/core';
import Loader from '../../../img/triangle-loader.svg';
import ExportItemToAccount from './ExportItemToAccount';
import { ExportItemContext } from '../../context/ExportItemContext';

const useStyles = makeStyles(theme => ({
  paper: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: 400,
    position: 'relative'
  },
  grid: {
    width: '100%',
    marginTop: 20
  },
  label: {
    marginBottom: 10
  },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  nested: {
    paddingLeft: theme.spacing(7),
    borderBottom: '1px solid lightgray',
    background: 'rgba(0, 0, 0, 0.04)'
  }
}))

const ExportItemTo = ({ accountList, submitState }) => {
  const classes = useStyles();
  const { importAccountList, setImportAccountList } = useContext(ExportItemContext);

  const checkboxHandler = (e, value) => {
    if (importAccountList.indexOf(value) === -1) {
      setImportAccountList([...importAccountList, value]);
    } else {
      const filteredList = importAccountList.filter(item => item.id !== value.id);
      setImportAccountList(filteredList);
    }
  }

  return (
    <Grid item className={classes.grid}>
      <InputLabel className={classes.label}>Import To</InputLabel>
      <Paper className={classes.paper}>
        <List>
          {accountList.map(account => (
            <ExportItemToAccount key={account.id} account={account} handleAccountCheckbox={checkboxHandler} />
          ))}
        </List>
        {submitState && <img src={Loader} alt="" className={classes.loader} />}
      </Paper>
    </Grid>
  )
}

export default ExportItemTo;