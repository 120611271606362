import React, { useState, useEffect, useContext } from 'react';
import { Button, FormControl, Grid, makeStyles, MenuItem, Select, TextField, Paper, InputLabel, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

import { apiPost } from '../../../lib/network';
import CustomSkeleton from '../../common/CustomSkeleton';
import CustomModal from '../../common/CustomModal';
import ExportItemCategory from './ExportItemCategory';
import ExportItemTo from './ExportItemTo';
import { ExportItemContext } from '../../context/ExportItemContext';
import { convertTimezoneValue } from '../../common/common';

const useStyles = makeStyles(theme => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  container: {
    padding: theme.spacing(4, 2, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: 400
  },
  grid: {
    width: '100%',
    marginTop: 20
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  button: {
    marginTop: 35
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  label: {
    marginBottom: 10
  },
  subheader: {
    padding: '10px 20px 10px',
    borderBottom: '1px solid lightgray',
    background: 'rgb(241, 241, 241)'
  },
  red: {
    color: 'red'
  }
}))

const ExportItem = ({accountList, isLoading, setIsLoading}) => {
  const classes = useStyles();
  const { importAccountList, setImportAccountList } = useContext(ExportItemContext);
  const { enqueueSnackbar } = useSnackbar();
  const snack = (msg, variant) => enqueueSnackbar(msg, { variant });
  const [nextState, setNextState] = useState(false);
  const [submitState, setSubmitState] = useState(false);

  const [exportAccount, setExportAccount] = useState('');
  const [searchedItem, setSearchedItem] = useState(null);
  const [seperatedCategories, setSeperatedCategories] = useState([]);
  const [optionsSelected, setOptionSelected] = useState([]);
  const [accountInfo, setAccountInfo] = useState({ email: '', timezone: '', dst: '', measurement: 1 });
  const [modalStatus, setModalStatus] = useState(false);

  // useEffect(() => {
  //   let isCancelled = false;
  //   const getDealers = async () => {
  //     const accountResponse = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: "wialon.getAccountsAll" });
  //     if (accountResponse.success) {
  //       const allAccountId = accountResponse.result.items.map(item => item.crt);
  //       const unitGroupsResponse = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: "wialon.getUnitgroupsInAccountByBatch", params: { accounts: allAccountId } });
  //       if (unitGroupsResponse.success) {
  //         for (let account of accountResponse.result.items) {
  //           for (const unitGroup of unitGroupsResponse.result) {
  //             if (account.crt + "" === unitGroup.searchSpec.propValueMask) {
  //               account.unit = unitGroup.items;
  //             }
  //           }
  //         }
  //       } else {
  //         accountResponse.result.items.forEach(item => item.unit = [])
  //       }

  //       setAccountList(JSON.parse(JSON.stringify(accountResponse.result.items)));
  //     }
  //     !isCancelled && setIsLoading(prevState => {
  //       return { account: false, option: prevState.option }
  //     });
  //   }
  //   getDealers();
  //   return () => { isCancelled = true }
  // }, [])

  useEffect(() => {
    if (!exportAccount) return;
    let isCancelled = false;
    // reset searchedItem && seperatedCategories

    if (!isCancelled) {
      setIsLoading(prevState => {
        return { account: prevState.account, options: true }
      })
      setSearchedItem(null);
      setSeperatedCategories({});
    }

    const getExportOptions = async () => {
      if (!exportAccount) return;
      const searchItem = await apiPost(process.env.REACT_APP_API_SERVER, {
        cmd: "wialon.searchItemById",
        params: {
          id: exportAccount,
          flags: 9007199254740991
        }
      });
      if (searchItem.success && !isCancelled) {
        setSearchedItem(searchItem.result.item);
      }
    }

    getExportOptions();

    return () => { isCancelled = true }
  }, [exportAccount])

  useEffect(() => {
    if (!searchedItem) return;
    let isCancelled = false;
    //rep - reports, ujb - jobs, unf - notifications, zl - geofences
    const options = ['ujb', 'unf', 'rep'];
    const seperatedOptions = [];
    const copiedValue = JSON.parse(JSON.stringify(searchedItem));
    for (let option of options) {
      let container = { category: option, list: [] };
      for (let key in copiedValue[option]) {
        if (container.list) { // if it already exists
          container.list.push(copiedValue[option][key]);
        } else {
          container.list = [copiedValue[option][key]]
        }
      }
      seperatedOptions.push(container);
    }

    if (!isCancelled) {
      setSeperatedCategories(seperatedOptions);
      setIsLoading(prevState => {
        return { account: prevState.account, options: false }
      })
    }


    return () => { isCancelled = true }
  }, [searchedItem])

  const renderCategories = () => {
    return seperatedCategories.map(item => {
      if (!item.list.length) return null
      return <ExportItemCategory
        value={item}
        key={item.category}
        option={optionsSelected}
        setOption={setOptionSelected}
      />
    }
    )
  }

  const disabledStateHandler = () => {
    if ((nextState && importAccountList.length) || (!nextState && optionsSelected.length)) {
      return false
    }
    return true
  }

  const submitHandler = async () => {
    //TODO: Add error snackbar if required fields are false
    const importAccountListArr = Object.values(importAccountList);
    if (!accountInfo.email || !importAccountListArr || !accountInfo.timezone) return
    setSubmitState(true);
    const response = await apiPost(process.env.REACT_APP_API_SERVER, {
      cmd: 'wialon.importItem',
      params: {
        importAccounts: importAccountListArr,
        options: optionsSelected,
        exportAccount,
        accountInfo
      }
    });
    if (response && response.success) {
      snack('Successfully Imported', 'success');
      setTimeout(() => window.location.reload(), 2000);
    } else {
      snack('Error importing:\nNo response - request timed out.', 'error')
    }
    setSubmitState(false);
  }

  return (
    <>
      <Typography className={classes.label}>For creating the new Notifications, Jobs, and Reports, please enter in the account's information:</Typography>
      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Email <span className={classes.red}>(required)</span></InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          value={accountInfo.email}
          onChange={e => setAccountInfo({ ...accountInfo, email: e.target.value })}
        />
      </Grid>

      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Measurement System <span className={classes.red}>(required)</span></InputLabel>
        <Select
          fullWidth
          variant="outlined"
          value={accountInfo.measurement}
          onChange={e => setAccountInfo({ ...accountInfo, measurement: e.target.value })}
        >
          <MenuItem value={0}>Metric</MenuItem>
          <MenuItem value={1}>US</MenuItem>
          <MenuItem value={2}>Imperial</MenuItem>
        </Select>
      </Grid>

      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Timezone <span className={classes.red}>(required)</span></InputLabel>
        <FormControl fullWidth>
          <Select
            id="timezone"
            variant="outlined"
            displayEmpty
            value={accountInfo.timezone}
            onChange={e => setAccountInfo({ ...accountInfo, timezone: e.target.value })}
          >
            <MenuItem value="" disabled>Choose</MenuItem>
            <MenuItem value="-28800"><strong>Pacific Time (US & Canada)</strong></MenuItem>
            <MenuItem value="-25200"><strong>Mountain Time (US & Canada)</strong></MenuItem>
            <MenuItem value="-21600"><strong>Central Time (US & Canada), Guadalajara, Mexico city</strong></MenuItem>
            <MenuItem value="-18000"><strong>Eastern time (US & Canada)</strong></MenuItem>
            <MenuItem value="-43200">International Date Line West</MenuItem>
            <MenuItem value="-39600">Midway Island, Samoa</MenuItem>
            <MenuItem value="-36000">Hawaii</MenuItem>
            <MenuItem value="-34200">French Polynesia, Marquesas Islands</MenuItem>
            <MenuItem value="-32400">Alaska</MenuItem>
            <MenuItem value="-16200">Venezuela</MenuItem>
            <MenuItem value="-14400">Atlantic time (Canada), Manaus, Santiago</MenuItem>
            <MenuItem value="-12600">Newfoundland</MenuItem>
            <MenuItem value="-10800">Greenland, Brasilia, Montevideo</MenuItem>
            <MenuItem value="-7200">Mid-Atlantic</MenuItem>
            <MenuItem value="-3600">Azores</MenuItem>
            <MenuItem value="0">GMT: Dublin, Edinburgh, Lisbon, London</MenuItem>
            <MenuItem value="3600">Amsterdam, Berlin, Rome, Vienna, Prague, Brussels</MenuItem>
            <MenuItem value="7200">Athens, Istanbul, Beirut, Cairo, Jerusalem</MenuItem>
            <MenuItem value="10800">St. Petersburg, Minsk, Baghdad, Moscow</MenuItem>
            <MenuItem value="12600">Iran</MenuItem>
            <MenuItem value="14400">Volgograd, Baku, Yerevan</MenuItem>
            <MenuItem value="16200">Afghanistan</MenuItem>
            <MenuItem value="18000">Yekaterinburg, Tashkent</MenuItem>
            <MenuItem value="19800">Chennai, Kolkata, Mumbai, New Delhi</MenuItem>
            <MenuItem value="20700">Nepal</MenuItem>
            <MenuItem value="21600">Omsk, Almaty</MenuItem>
            <MenuItem value="23400">Myanmar, Cocos Islands</MenuItem>
            <MenuItem value="25200">Indochina Time (ICT)</MenuItem>
            <MenuItem value="28800">Krasnoyarsk, Ulaan Bataar, Perth</MenuItem>
            <MenuItem value="32400">Irkutsk</MenuItem>
            <MenuItem value="34200">Australian Central Standard Time</MenuItem>
            <MenuItem value="36000">Yakutsk, Canberra, Melbourne, Sydney, Hobart</MenuItem>
            <MenuItem value="37800">Lord Howe Standard Time</MenuItem>
            <MenuItem value="39600">Vladivostok, Solomon Is., New Caledonia</MenuItem>
            <MenuItem value="41400">Norfolk Islan</MenuItem>
            <MenuItem value="43200">Magadan, Auckland, Wellington</MenuItem>
            <MenuItem value="45900">New Zealand, Chatham Island</MenuItem>
            <MenuItem value="46800">Nuku'alofa</MenuItem>
            <MenuItem value="50400">Kiribati, Line Islands</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Daylight Savings Time (DST)</InputLabel>
        <FormControl fullWidth>
          <Select
            variant="outlined"
            value={accountInfo.dst}
            onChange={e => setAccountInfo({ ...accountInfo, dst: e.target.value })}
          >
            <MenuItem value="0x08000000">None</MenuItem>
            <MenuItem value="0x0A010000"><strong>United States, Canada</strong></MenuItem>
            <MenuItem value="0x0A050000">Russia</MenuItem>
            <MenuItem value="0x0B030000">Europe</MenuItem>
            <MenuItem value="0x0A040000">Jordan</MenuItem>
            <MenuItem value="0x0A060000">Syria</MenuItem>
            <MenuItem value="0x0A070000">Mexico (PSST), Turks & Caicos Islands</MenuItem>
            <MenuItem value="0x0A080000">Cuba</MenuItem>
            <MenuItem value="0x0A090000">Egypt</MenuItem>
            <MenuItem value="0x0A0B0000">Iraq</MenuItem>
            <MenuItem value="0x0A0C0000">Iran</MenuItem>
            <MenuItem value="0x0A200000">Falkland Islands</MenuItem>
            <MenuItem value="0x0A210000">Namibia</MenuItem>
            <MenuItem value="0x0A220000">New Zealand</MenuItem>
            <MenuItem value="0x0A230000">Chile</MenuItem>
            <MenuItem value="0x0A240000">Brazil</MenuItem>
            <MenuItem value="0x0A250000">Paraguay</MenuItem>
            <MenuItem value="0x0A270000">Australia</MenuItem>
            <MenuItem value="0x0A280000">Tasmania</MenuItem>
            <MenuItem value="0x0A290000">Fiji</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Source Items</InputLabel>
        {!isLoading.account
          ? <Autocomplete
            options={accountList}
            getOptionLabel={options => options.nm}
            disableClearable
            disabled={nextState}
            onChange={(e, v) => {
              setImportAccountList([]);
              setOptionSelected([]);
              setExportAccount(v.bact)
            }}
            renderOption={option => <span>{option.nm}</span>}
            renderInput={params => <TextField
              {...params}
              variant="outlined"
              placeholder={!accountList.length ? 'No items found' : 'Choose'}
            />}
          />
          : <CustomSkeleton />
        }
      </Grid>
      {!nextState
        ? <Grid item className={classes.grid}>
          <InputLabel className={classes.label}>Export Options</InputLabel>
          <Paper className={classes.paper}>
            {isLoading.options ? <CustomSkeleton style={{ border: 'none', margin: 10, height: 40 }} number={7} /> : null}
            {seperatedCategories.length && !isLoading.options ? renderCategories() : null}
          </Paper>
        </Grid>
        : <ExportItemTo accountList={accountList} submitState={submitState} />
      }
      <Grid item className={classes.grid} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {nextState
          ? <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: 10 }}
            onClick={() => {
              setNextState(false)
            }}
          >
            Back
          </Button>
          : null
        }
        {!nextState
          ? <Button
            variant="contained"
            color="primary"
            disabled={disabledStateHandler()}
            onClick={() => setNextState(true)}
          >
            Next
            </Button>
          : <Button
            variant="contained"
            color="primary"
            disabled={submitState || !accountInfo.email || !accountInfo.timezone}
            onClick={() => setModalStatus(true)}
          >
            Submit
            </Button>
        }

        <CustomModal
          status={modalStatus}
          setStatus={setModalStatus}
          onSubmit={submitHandler}
          title={'Please Confirm To Add Default Template.'}
          renderMessage={() => {
            let notIncluded = [];
            for (let id in importAccountList) {
              if (!importAccountList[id].unit.length) {
                notIncluded.push(importAccountList[id].nm);
              }
            }

            if (!notIncluded.length) return;
            return (
              < Grid item style={{ margin: '10px 0 10px' }} >
                <Typography gutterBottom variant="subtitle2">The following account(s) will not include the notifications template: <strong>{notIncluded.join(', ')}</strong></Typography>
                <Typography color="error" variant="subtitle2">Please note if the account has existing templates with the same name, those templates will be not be added. Notifications template will not be added to any accounts without unit groups. </Typography>
              </Grid>
            )
          }
          } />
      </Grid>
    </>
  )
}

export default ExportItem;