import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useRoutes } from 'hookrouter';
import localforage from "localforage";

import Header from './Header';
import MenuDrawer from './MenuDrawer';
import Login from './Auth/Login';
import AddDevice from './Devices/AddDevice';
import Dashboard from './Dashboard';
import NewCustomer from './Customer/NewCustomer';
import NotFoundPage from './NotFoundPage';
import Export from './WLP/Export/Export';
import Import from './WLP/Import/Import';
import Reports from './Reports/Reports';
import { AppContext } from '../AppContext';

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderWidth: 1.5
      }
    },
    MuiInputBase: {
      root: {
        background: 'white'
      }
    }
  },
});

const useStyles = makeStyles(theme => ({
  wrapperDiv: {
    [theme.breakpoints.down("xs")]: { overflow: "auto" }
  }
}))

const App = () => {
  const classes = useStyles();
  const { appState, setAppState, loadingDOM, setLoadingDOM } = useContext(AppContext);
  const [menuState, setMenuState] = useState(false);

  useEffect(() => {
    const setStateFromStorage = async () => {
      const storedState = await localforage.getItem("appState");
      if (storedState !== null) {
        await setAppState(storedState);
      }
      setLoadingDOM(false);
    }

    const wait = async () => {
      setStateFromStorage();
    };
    wait();
  }, [])

  const routeResultNonAuth = useRoutes({
    '/*': () => <Login />
  })

  const routeResultAuth = useRoutes({
    '/': () => <Dashboard />,
    '/add-devices': () => <AddDevice />,
    '/new-customer': () => <NewCustomer />,
    '/export-wlp': () => <Export />,
    '/import-wlp': () => <Import />,
    '/reports': () => {
      if ( appState.auth.user === 'miplatform' ) {
        return <Reports />
      }
    }
  })

  const renderRoute = () => {
    if (loadingDOM) {
      return <></>
    } else {
      if (appState.auth.loggedIn) {
        return routeResultAuth || <NotFoundPage />;
      } else {
        return routeResultNonAuth || <NotFoundPage />;
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.wrapperDiv}>
        <Header state={menuState} setState={setMenuState} />
        <Container  >
          {renderRoute()}
        </Container>
        <MenuDrawer state={menuState} setState={setMenuState} />
      </div>
    </ThemeProvider>
  )
}

export default App;