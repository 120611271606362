import React, { useEffect, useState } from 'react';
import { Grid, InputLabel, makeStyles, MenuItem, Button, TextField, Select } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { saveAs } from 'file-saver';

import { apiPost } from '../../../lib/network';
import CustomSkeleton from '../../common/CustomSkeleton';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  grid: {
    width: '100%',
    marginTop: 20
  },
  label: {
    marginBottom: 10
  },
  button: {
    marginTop: 35
  }
}))

const ExportFile = ({accountList, isLoading, setIsLoading}) => {
  const classes = useStyles();
  const [unitGroupList, setUnitGroupList] = useState([]);
  const [payload, setPayload] = useState({ accountId: '', accountName: '', unitGroupId: '', emailAddress: '', timezone: '', units: '' });
  const [deviceType, setDeviceType] = useState('calamp');

  // useEffect(() => {
  //   let isCancelled = false;

  //   const getDealers = async () => {
  //     const response = await apiPost(process.env.REACT_APP_API_SERVER, { cmd: "wialon.getAccountsAll" });
  //     if (response.success && !isCancelled) {
  //       const list = response.result.items;
  //       setAccountList(list || []);
  //     } else {
  //       console.error(response);
  //     }
  //     if (!isCancelled) setIsLoading(prevState => {
  //       return { account: false, unitGroup: prevState.unitGroup }
  //     });
  //   }
  //   getDealers();
  //   return () => { isCancelled = true }
  // }, [])

  useEffect(() => {
    let isCancelled = false;
    if (!payload.accountId) return;

    if (!isCancelled) setIsLoading(prevState => {
      return { account: prevState.account, unitGroup: true }
    });

    const getUnitGroup = async () => {
      const response = await apiPost(process.env.REACT_APP_API_SERVER, {
        cmd: "wialon.getUnitgroupsInAccount",
        params: {
          creatorId: payload.accountId
        }
      });
      if (response.success && !isCancelled) {
        const list = response.result.items;
        setUnitGroupList(list || []);
      } else {
        console.error(response);
      }

      if (!isCancelled) setIsLoading(prevState => {
        return { account: prevState.account, unitGroup: false }
      })
    }

    const getAccountDetails = async () => {
      const response = await apiPost(process.env.REACT_APP_API_SERVER, {
        cmd: "wialon.searchItemById",
        params: {
          id: payload.accountId,
          flags: 3
        }
      });
      if (response && response.success && !isCancelled) {
        const result = response.result.item;
        setPayload({ ...payload, emailAddress: result.prp.email, timezone: result.prp.tz })
      } else {
        console.error(response);
      }
    }

    getAccountDetails();
    getUnitGroup();

    return () => { isCancelled = true }
  }, [payload.accountId])

  const submitHandler = async () => {
    const { accountId, accountName, unitGroupId, emailAddress, timezone, units } = payload;
    if (!accountId || !unitGroupId) return;

    const response = await apiPost(process.env.REACT_APP_API_SERVER, {
      cmd: 'wialon.makeWlp',
      params: {
        unitGroupId,
        emailAddress,
        timezone,
        units,
        deviceType
      }
    });
    if (response) {
      if (response.success) {
        const blob = new Blob([response.result], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `NRJ WLP ${accountName} ${accountId}.wlp`, { autoBOM: false });
      }
    }
  }

  return (
    <>
      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Account</InputLabel>
        {!isLoading.account
          ? <Autocomplete
            options={accountList}
            getOptionLabel={options => options.nm}
            disableClearable
            onChange={(e, v) => setPayload({ ...payload, accountId: v.crt, accountName: v.nm, units: v.mu })}
            renderOption={option => <span>{option.nm}</span>}
            renderInput={params => <TextField
              {...params}
              variant="outlined"
              placeholder={!accountList.length ? 'No items found' : 'Choose'}
            />}
          />
          : <CustomSkeleton />
        }
      </Grid>
      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Unit Group</InputLabel>
        {!isLoading.unitGroup
          ? <Autocomplete
            options={unitGroupList}
            getOptionLabel={options => options.nm}
            disableClearable
            onChange={(e, v) => setPayload({ ...payload, unitGroupId: v.id })}
            renderOption={option => <span>{option.nm}</span>}
            renderInput={params => <TextField
              {...params}
              variant="outlined"
              placeholder={!unitGroupList.length ? 'No items found' : 'Choose'}
            />}
          />
          : <CustomSkeleton />
        }
      </Grid>
      <Grid item className={classes.grid}>
        <InputLabel className={classes.label}>Device Type</InputLabel>
        <Select
          variant="outlined"
          fullWidth
          value={deviceType}
          onChange={e => setDeviceType(e.target.value)}
        >
          <MenuItem value="calamp">Calamp</MenuItem>
          <MenuItem value="geometris">Geometris</MenuItem>
        </Select>
      </Grid>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        disabled={!payload.accountId || !payload.unitGroupId}
        className={classes.button}
        onClick={submitHandler}
      >
        Export
        </Button>
    </>
  )
}

export default ExportFile;